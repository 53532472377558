import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css/bundle';
import { Url } from './Url';
import { Link } from 'react-router-dom';


const SliderPrincipal = () => {
    const [sliders, setSliders] = useState([]);

    useEffect(() => {
        const query = async () => {
            const url = `${Url}api/slideprincipal.php`;
            const resultado = await axios.get(url);
            setSliders(resultado.data.results);
        }
        query();
    }, [])


    let slides = [];

    sliders && sliders.map((slide, index) => (
        slides.push(
            <SwiperSlide key={index}>
                <Link
                    to={`/${slide.link}`}
                >
                    <div className='bg-black w-full h-screen z-100 absolute opacity-50'>
                    </div>
                    <div className='w-full h-screen z-50 absolute pt-10'>
                        <div className='w-full h-screen'>
                            <blockquote>
                                <h1 className='text-white text-5xl text-center uppercase mt-10'>{slide.titulo}</h1>
                            </blockquote>
                            <p className='text-white text-2xl text-center mt-5'>{slide.contenido}</p>

                        </div>
                    </div>
                    <img className='h-screen w-full object-cover' src={slide.imagen} alt={slide.titulo} />
                </Link>
            </SwiperSlide>
        )
    ));


    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            observer={true}
            centeredSlides={true}
            autoplay={{
                delay: 3500,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}

            speed={500}
            loop={true}
            touchRatio={1.5}
            effect={"fade"}
            className="mySwiper"
        >
            {slides}
        </Swiper>
    );
}

export default SliderPrincipal