import { useContext } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { motion } from 'framer-motion';
import authContext from '../context/auth/authContext'
import Alerta from './Alerta'


export default function FormRegistro(props) {
    const AuthContext = useContext(authContext);

    const { mensaje, registrarUsuario, registradoOk } = AuthContext;


    const formik = useFormik({
        initialValues:{
            nombre: '',
            apellido: '',
            telefono: '',
            mail: '',
            password: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("El Nombre es obligatorio"),
            apellido: Yup.string()
                .required("El Apellido es obligatorio"),
            telefono: Yup.number()
                .integer('El número no es válido')
                .positive('El número no es válido')
                .typeError('El número no es válido')
                .min(10,'El número es demasiado corto')
                .required('El teléfono es obligatorio'),
            mail: Yup.string()
                .required("El email es obligatorio")
                .email("El email no es correcto"),
            password: Yup.string()
                .required("Debe ingresar un Password")
                .min(6,"Debe contar con un mínimo de 6 caracteres")
        }),
        onSubmit: (valores,{resetForm}) => {
            registrarUsuario(valores)
            resetForm({ values: ''});
        }
    });
    const areaAnimate={
        offscreen:{x:0, opacity:0},
        onscreen:{
            x:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    //const cancelButtonRef = useRef(null)

    return (
        <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{once:false, amount: 0.1}}
            variants={areaAnimate}
        >
            { mensaje ? <Alerta /> : null }
            <form
                className="w-full mx-auto mt-10 p-5 bg-indigo-600 rounded-2xl"
                onSubmit={formik.handleSubmit}
            >
                { !registradoOk ? (
                    <>
                    <div className="transition-all w-full pt-5 mt-5">
                        <h2 className='text-center uppercase text-white text-2xl'>Formulario de Registro</h2>
                        <div className="sm:items-start px-5 mt-5">
                            <label htmlFor="apellido" className="block text-white uppercase font-bold">Apellido</label>
                            <input
                                type="text"
                                className="py-2 px-3 w-full rounded"
                                id="apellido"
                                placeholder="Ingrese su Apellido"
                                value={formik.values.apellido}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.apellido && formik.errors.apellido ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.apellido}</p>
                                </div>
                            ) : null }
                        </div>
                        <div className="sm:items-start px-5 mt-2">
                            <label htmlFor="nombre" className="block text-white uppercase font-bold">Nombre</label>
                            <input
                                type="text"
                                className="py-2 px-3 w-full rounded"
                                id="nombre"
                                placeholder="Ingrese su Nombre"
                                value={formik.values.nombre}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.nombre && formik.errors.nombre ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.nombre}</p>
                                </div>
                            ) : null }
                        </div>
                        <div className="sm:items-start px-5 mt-2">
                            <label htmlFor="telefono" className="block text-white uppercase font-bold">Teléfono</label>
                            <input
                                type="tel"
                                className="py-2 px-3 w-full rounded"
                                id="telefono"
                                placeholder="Ingrese su número de teléfono"
                                value={formik.values.telefono}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.telefono && formik.errors.telefono ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.telefono}</p>
                                </div>
                            ) : null }
                        </div>
                        <div className="sm:items-start px-5 mt-2">
                            <label htmlFor="email" className="block text-white uppercase font-bold">E-mail</label>
                            <input
                                type="email"
                                className="py-2 px-3 w-full rounded"
                                id="mail"
                                placeholder="Ingrese su E-mail"
                                value={formik.values.mail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.mail && formik.errors.mail ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.mail}</p>
                                </div>
                            ) : null }
                        </div>
                        <div className="sm:items-start px-5 mt-2">
                            <label htmlFor="password" className="block text-white uppercase font-bold">Contraseña</label>
                            <input
                                type="password"
                                className="py-2 px-3 w-full rounded"
                                id="password"
                                placeholder="Ingrese una Contraseña"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.password && formik.errors.password ? (
                                <div className='w-full block text-red-500'>
                                    <p>{formik.errors.password}</p>
                                </div>
                            ) : null }
                        </div>
                    </div>
                    <div className="bg-indigo-600 pt-10 pb-10">
                        <div className="bg-indigo-600 w-full rounded-lg px-5">
                            <input
                                type="submit"
                                className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 hover:cursor-pointer transition-all uppercase font-bold rounded-lg mt-1"
                                value="Crear Cuenta"
                            />
                        </div>
                    </div>
                    </>                
                ) : ( 
                    <div className="bg-indigo-600 pb-10 mt-10">
                        <div className="bg-indigo-600 w-full rounded-lg px-5">
                            <button
                                className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 hover:cursor-pointer transition-all uppercase font-bold rounded-lg mt-1"
                            >Finalizar</button>
                        </div>
                    </div>
                )
                }
            </form>
        </motion.div>
    )
}