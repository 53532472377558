import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Entrada from "./Entrada";
import { motion } from 'framer-motion';
import { Url } from './Url';


const ListadoDestinos = () => {
    const [Destinos, setDestinos] = useState([]);

    const areaAnimate = {
        offscreen: { y: 400, opacity: 0 },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 2
            }
        }
    }


    useEffect(() => {
        const des = async () => {
            const url = Url + 'api/destinos.php';
            const resultado = await axios.get(url);
            //console.log(resultado)
            setDestinos(resultado.data.results);
        }
        des();
    }, [])

    return (
        <>
            {
                Destinos && Destinos.map((destino, index) => (
                    <a href={`/destino/${destino.id}`} key={index}>
                        <motion.div className="bg-black/30 rounded-xl drop-shadow-md hover:drop-shadow-xl"
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{ once: true, amount: 0.1 }}
                            variants={areaAnimate}
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 1 },
                            }}
                        >
                            <div className='h-72 relative'>
                                <Entrada
                                    slide={destino}
                                />
                            </div>
                            <div className='pt-3 pb-3'>
                                <h1 className='text-white text-2xl text-center '>{destino.nombredestino}</h1>
                            </div>
                        </motion.div>
                    </a>
                ))
            }
        </>
    );
}

export default ListadoDestinos