import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AuthState from './context/auth/authState'
import ModalForm from './components/ModalForm'
import ModalLogin from './components/ModalLogin'
import ModalVista from './components/ModalVista'
import Home from './paginas/Index'
import Estudiantil from './paginas/Estudiantil'
import Grupal from './paginas/Grupal'
import Individual from './paginas/Individual'
import Receptivo from './paginas/receptivo'
import Nacional from './paginas/nacional'
import Internacional from './paginas/internacional'
import Destinos from './paginas/Destinos'
import Turismo from './paginas/Turismo'
import Destino from './paginas/Destino'
import Promociones from './paginas/Promociones'
import Nosotros from './paginas/Nosotros'
import Reservas from './paginas/Reservas'
import Cuentas from './paginas/Cuentas'
import Login from './paginas/Login'
import Reserva from './paginas/Reserva'
import RecuperarCuenta from './paginas/RecuperarCuenta'
import RestablecerPassword from './paginas/RestablecerPassword'
import Mpsuccess from './paginas/Mpsuccess'
import Mppending from './paginas/Mppending'
import Mpfailure from './paginas/Mpfailure'
import GestionReservas from './paginas/admin/GestionReservas'
import GestionConsultas from './paginas/admin/GestionConsultas'
import HomeAdmin from './paginas/admin/Index'
import LoginAdmin from './paginas/admin/Login'
import Configuracion from './paginas/admin/Configuracion'
import AltaPaquetes from './paginas/admin/AltaPaquetes'
import SetPassword from './paginas/admin/SetPassword'
import GestionUsuarios from './paginas/admin/GestionUsuarios'
import GestionClientes from './paginas/admin/GestionClientes'
import appContext from './context/auth/appContext'
import Newsletter from './paginas/admin/Newsletter'

const App = ({ Component, pageProps }) => {
	const [openLogin, setOpenLogin] = useState(false)
	const [verLogin, setVerLogin] = useState(true)
	const [openConsulta, setOpenConsulta] = useState(false);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [colegio, setColegio] = useState(0);
	const [curso, setCurso] = useState(0);
	const [colegioSel, setColegioSel] = useState(0);
	const [paquete, setPaquete] = useState(0);
	const [idDestino, setIdDestino] = useState(0);
	const [idHotel, setIdHotel] = useState(0);
	const [daySelected, setDaySelected] = useState(1);
	const [monthSelected, setMonthSelected] = useState(1);
	const [yearSelected, setYearSelected] = useState(1910);
	const [fechaN, setFechaN] = useState('');
	const [fechaNT, setFechaNT] = useState('');
	const [useDate, setUseDate] = useState(0);
	const [habilitado, setHabilitado] = useState(false);
	const [openVista, setOpenVista] = useState(false);
	const [tipoModal, setTipoModal] = useState(0);
	const [usuarioSelected, setUsuarioSelected] = useState({});
  const [cliMod, setCliMod] = useState(0);

	return (
		<AuthState>
			<appContext.Provider
				value={{
					openLogin, setOpenLogin,
					verLogin, setVerLogin,
					openConsulta, setOpenConsulta,
					openDatePicker, setOpenDatePicker,
					colegio, setColegio,
					curso, setCurso,
					colegioSel, setColegioSel,
					paquete, setPaquete,
					idDestino, setIdDestino,
					idHotel, setIdHotel,
					daySelected, setDaySelected,
					monthSelected, setMonthSelected,
					yearSelected, setYearSelected,
					fechaN, setFechaN,
					fechaNT, setFechaNT,
					useDate, setUseDate,
					habilitado, setHabilitado,
					openVista, setOpenVista,
					tipoModal, setTipoModal,
					usuarioSelected, setUsuarioSelected,
          cliMod, setCliMod
				}}
			>
				<BrowserRouter>
					<Routes>
						<Route path='/turismo/:id' element={<Turismo />} />
						<Route path='/destino/:id' element={<Destino />} />
						<Route path='/reserva/:id' element={<Reserva />} />
						<Route path='/' element={<Home />} />
						<Route path='/estudiantil' element={<Estudiantil />} />
						<Route path='/grupal' element={<Grupal />} />
						<Route path='/nacional' element={<Nacional />} />
						<Route path='/internacional' element={<Internacional />} />
						<Route path='/receptivo' element={<Receptivo />} />
						<Route path='/destinos' element={<Destinos />} />
						<Route path='/promociones' element={<Promociones />} />
						<Route path='/nosotros' element={<Nosotros />} />
						<Route path='/reservas' element={<Reservas />} />
						<Route path='/cuentas' element={<Cuentas />} />
						<Route path='/login' element={<Login />} />
						<Route path='/recuperarcuenta' element={<RecuperarCuenta />} />
						<Route path='/restablecerpassword' element={<RestablecerPassword />} />
						<Route path='/mpsuccess' element={<Mpsuccess />} />
						<Route path='/mppending' element={<Mppending />} />
						<Route path='/mpfailure' element={<Mpfailure />} />
						<Route path='/admin/' element={<HomeAdmin />} />
						<Route path='/admin/gestionreservas' element={<GestionReservas />} />
						<Route path='/admin/gestionconsultas' element={<GestionConsultas />} />
						<Route path='/admin/altapaquetes' element={<AltaPaquetes />} />
						<Route path='/admin/configuracion' element={<Configuracion />} />
						<Route path='/admin/setpassword' element={<SetPassword />} />
						<Route path='/admin/gestionusuarios' element={<GestionUsuarios />} />
						<Route path='/admin/gestionclientes' element={<GestionClientes />} />
						<Route path='/admin/login' element={<LoginAdmin />} />
						<Route path='/admin/newsletter' element={<Newsletter />} />
					</Routes>
					<ModalForm />
					<ModalLogin />
					<ModalVista />
				</BrowserRouter>
			</appContext.Provider>
		</AuthState>
	)
}

export default App
