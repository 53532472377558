import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from '@headlessui/react'

import axios from "axios";
import { Url } from "../Url";
import { useFormik } from "formik"
import * as Yup from "yup"


const FormUsuario = (props) => {
    const [ open, setOpen ] = useState(false);
    const [ encontrado, setEncontrado] = useState(0)
    const [ guardar, setGuardar ] = useState(false)

    const cancelButtonRef = useRef(null)

    const formik = useFormik({
        initialValues:{
            nombre:'',
            apellido:'',
            telefono:''
        },
        validationSchema: Yup.object({
            nombre:Yup.string()
                .min(3,'El nombre es muy corto')
                .required('El nombre es obligatorio'),
            apellido:Yup.string()
                .min(3,'El apellido es muy corto')
                .required('El apellido es obligatorio'),
            telefono:Yup.number()
                .integer('El número no es válido')
                .positive('El número no es válido')
                .typeError('El número no es válido')
                .min(10,'El número es demasiado corto'),
            mail:Yup.string()
                .email('Email no válido')
                .required('El email es obligatorio')
        }),
        onSubmit: async (valores,{resetForm}) => {
            const formData=new FormData()
            formData.append('nombre',valores.nombre);
            formData.append('apellido',valores.apellido);
            formData.append('telefono',valores.telefono);
            formData.append('mail',valores.mail);
            try{
                const response = await axios({
                    url: Url+'api/nuevocliente.php',
                    method: 'POST',
                    data: formData,
                })
                
                resetForm()
                setOpen(false)
            } catch (e) {
              console.log(e)
            }
        }
    });

    useEffect(()=>{
        const checkMail = async () => {
            const formData=new FormData()
            formData.append('mail',formik.values.mail);
            try{
                const response = await axios({
                    url: Url+'api/checkmail.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(response)
                setEncontrado(response.data.results.encontrado)
            } catch (e) {
                console.log(e)
            }
        }
        checkMail()    
    },[formik.values.mail])

    useEffect(()=>{
        if ((encontrado===0 || encontrado==='0'))
        {
            console.log("por aqui")
            if (formik.touched.mail && formik.errors.mail)
            {
                console.log(guardar)
                setGuardar(false)
            }
            else
            {
                console.log(guardar)
                setGuardar(true)
            }
        }
        else
        {
            console.log("no por aqui")
            setGuardar(false)
        }
    },[encontrado,formik.values.mail])

    return(
        <>
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative bg-gray-300 rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-10/12 w-full">
                            <div className="px-4 pt-5 sm:p-6">
                                <h2 className="text-3xl text-center text-gray-600 font-bold uppercase">Agregar Clientes</h2>
                                <form
                                    onSubmit={formik.handleSubmit}
                                >
                                    <>
                                        <div className="transition-all px-4 w-full pt-5 sm:p-6">
                                            <div className="sm:items-start mt-2">
                                                <label htmlFor="apellido" className="block text-gray-600 uppercase font-bold">Apellido</label>
                                                <input
                                                    type="text"
                                                    className="py-3 px-3 w-full rounded"
                                                    id="apellido"
                                                    placeholder="Ingrese Apellido"
                                                    value={formik.values.apellido}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                { formik.touched.apellido && formik.errors.apellido ? (
                                                    <div className='bg-red-500 text-center uppercase text-white rounded'>
                                                        <p>{formik.errors.apellido}</p>
                                                    </div>
                                                ) : null }
                                            </div>
                                            <div className="sm:items-start mt-2">
                                                <label htmlFor="nombre" className="block text-gray-700 uppercase font-bold">Nombre</label>
                                                <input
                                                    type="text"
                                                    className="py-3 px-3 w-full rounded"
                                                    id="nombre"
                                                    placeholder="Ingrese Nombre"
                                                    value={formik.values.nombre}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                { formik.touched.nombre && formik.errors.nombre ? (
                                                    <div className='bg-red-500 text-center uppercase text-white rounded'>
                                                        <p>{formik.errors.nombre}</p>
                                                    </div>
                                                ) : null }
                                            </div>
                                            <div className="sm:items-start mt-2">
                                                <label htmlFor="telefono" className="block text-gray-700 uppercase font-bold">Nº de Teléfono Celular, Sin 0 y sin 15 (Ej. 3815123456)</label>
                                                <input
                                                    type="tel"
                                                    className="py-3 px-3 w-full rounded"
                                                    id="telefono"
                                                    placeholder="Nº de tel. Celular"
                                                    value={formik.values.telefono}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                { formik.touched.telefono && formik.errors.telefono ? (
                                                    <div className='bg-red-500 text-center uppercase text-white rounded'>
                                                        <p>{formik.errors.telefono}</p>
                                                    </div>
                                                ) : null }
                                            </div>
                                            <div className="sm:items-start mt-2">
                                                <label htmlFor="mail" className="block text-gray-700 uppercase font-bold">E-mail</label>
                                                <input
                                                    type="mail"
                                                    className="py-3 px-3 w-full rounded"
                                                    id="mail"
                                                    placeholder="Ingrese su E-mail"
                                                    value={formik.values.mail}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                { formik.touched.mail && formik.errors.mail ? (
                                                    <div className='bg-red-500 text-center uppercase text-white rounded'>
                                                        <p>{formik.errors.mail}</p>
                                                    </div>
                                                ) : null }
                                                { encontrado===1 && (
                                                    <div className='bg-red-500 text-center uppercase text-white rounded'>
                                                        <p>El Email ya existe en la Base de Datos</p>
                                                    </div>
                                                ) }
                                            </div>
                                        </div>
                                        <div className="px-4 pb-10 sm:px-6">
                                            <div className="w-full rounded-lg">
                                                { guardar && (
                                                <input
                                                    type="submit"
                                                    className="w-full shadow-md p-3 bg-indigo-600 text-gray-100 hover:text-gray-300 hover:cursor-pointer transition-all uppercase font-bold rounded-lg mt-1"
                                                    value="Guardar"
                                                />
                                                )}
                                            </div>
                                        </div>
                                    </>                
                                </form>
                            </div>
                            <div className="px-4 pb-10 sm:px-6">
                                <div className="w-full rounded-lg px-5">
                                    <button
                                        type="button"
                                        className="w-full shadow-sm p-3 bg-gray-600 hover:bg-gray-500 transition-all text-white uppercase font-bold rounded-xl mt-1"
                                        onClick={() => setOpen(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
        </Transition.Root>
        <button className='bg-orange-600 hover:bg-orange-400 p-1 rounded-lg text-white uppercase'
            onClick={() => setOpen(true)}
        >
            Agregar Cliente
        </button>
    </>

    )
}

export default FormUsuario