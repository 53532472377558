import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Url } from './Url';



const ImagenesHotel = ({ idhotel }) => {
    const [hotel, setHotel] = useState([]);

    const ho = async () => {
        const url = Url + 'api/imageneshotel.php?idhotel=' + idhotel;
        const resultado = await axios.get(url);
        //console.log(resultado)
        setHotel(resultado.data.results);
    }

    useEffect(() => {
        ho();
    }, [])

    return (
        <>
            {
                hotel.map((hot, index) => (
                    <div className='w-full h-72 relative mt-2' key={index}>
                        <img className="rounded-xl h-72 w-full object-cover" src={Url + hot.imagen} alt="img"></img>
                    </div>
                ))
            }
        </>
    );
}

export default ImagenesHotel