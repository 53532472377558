import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Url } from './Url';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css/bundle';



const Servicios = ({ idservicio, lnk }) => {
    const [subTiposPaquetes, setSubTiposPaquetes] = useState([]);


    useEffect(() => {
        const tiposPQ = async () => {
            const url = `${Url}api/subtipospaquetes.php?idtipo=${idservicio}`;
            const resultado = await axios.get(url);
            setSubTiposPaquetes(resultado.data.results);
        }
        tiposPQ();
    }, [])

    let slides = [];

    subTiposPaquetes && subTiposPaquetes.map((tipo, index) => (
        slides.push(
            <SwiperSlide className='h-72' key={index}>
                <a href={'/' + lnk}>
                    <div className='w-full h-72 z-50 rounded-2xl absolute pt-10'>
                        <h1 className='text-white text-5xl text-center uppercase mt-10'>{tipo.nombretipo}</h1>
                        <h2 className='text-white text-2xl text-center mt-10'>{tipo.nombresubtipo}</h2>
                    </div>
                    <div className={'bg-black w-full h-72 z-100 rounded-2xl absolute opacity-50'} >
                    </div>
                    <img className='object-cover w-full h-72 rounded-2xl' src={`${Url}${tipo.imagen}`} alt="img" />
                </a>
            </SwiperSlide>
        )
    ));


    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            observer={true}
            centeredSlides={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}

            modules={[Autoplay, Pagination, Navigation]}

            speed={500}
            loop={true}
            touchRatio={1.5}
            effect={"fade"}
            className="mySwiper"
        >
            {slides}
        </Swiper>
    );
}

export default Servicios