import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Url } from './Url';
import { areaAnimateLR } from './Animaciones';


const PaqueteProgramas = ({ idpaquete }) => {
    const [programas, setProgramas] = useState([]);

    useEffect(() => {
        const prog = async () => {
            console.log('Paquete ' + idpaquete)
            const url = `${Url}api/paqueteprogramas.php?idpaquete=${idpaquete}`;
            const resultado = await axios.get(url);
            console.log(resultado)
            setProgramas(resultado.data.results);
        }
        prog();
    }, [])

    return (
        <>
            {
                programas ? (
                    programas.map((programa, index) => (
                        <div className="w-full" key={index}>
                            <motion.div
                                className='w-full relative mt-2'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{ once: true, amount: 0.1 }}
                                variants={areaAnimateLR}
                            >
                                <img className="rounded-xl w-full object-cover" src={Url + programa.imagen} alt="img"></img>
                            </motion.div>
                            <motion.div
                                className='w-full pt-3 pb-3'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{ once: true, amount: 0.1 }}
                                variants={areaAnimateLR}
                            >
                                <h1 className='text-orange-400 text-3xl text-center font-semibold font-roboto'>{programa.nombreprograma}</h1>
                                <h3 className='text-orange-400 text-xl text-center font-roboto'>{programa.descripcion}</h3>
                            </motion.div>
                        </div>
                    ))) : null
            }
        </>
    );
}

export default PaqueteProgramas