import { useState, useEffect } from 'react';
import Layout from '../components/Layout'
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import { Url } from '../components/Url';
import { useFormik } from "formik"
import * as Yup from "yup"

import { Link } from "react-router-dom";

const RestablecerPassword = () => {
    const [ restablecer, setRestablecer ] = useState(false);

    const query = new URLSearchParams(useLocation().search);
    const mailQ = query.get('mail');
    const tkQ = query.get('tk');
    console.log(tkQ)

    useEffect(() => {
        //console.log("llega");
        const verificarDatos = async () => {
            //console.log("llega");
            const formData=new FormData()
            formData.append('mail',mailQ);
            formData.append('tk',tkQ);
            try{
                const response = await axios({
                    url: Url+'api/verificardatos.php',
                    method: 'POST',
                    data: formData,
                })
                if (response.data === 1)
                    setRestablecer(true)
                } catch (e) {
                    console.log(e)
                }    
        }

        verificarDatos()
    }, [])
    


    const formik = useFormik({
        initialValues:{
            password: '',
            password2: ''
        },
        validationSchema: Yup.object({
            password: Yup.string()
                    .required("Debe ingresar un Password")
                    .min(6,"Debe contar con un mínimo de 6 caracteres"),
            password2: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
        }),
        onSubmit: async valores => {
            const formData=new FormData()
            formData.append('mail',mailQ);
            formData.append('password',valores.password);
            try{
                const response = await axios({
                    url: Url+'api/restablecerpassword.php',
                    method: 'POST',
                    data: formData,
                })
                if (response.data === 1)
                    setRestablecer(false)
                } catch (e) {
                console.log(e)
            }    

            //iniciarSesion(valores)
            //console.log(valores)
        }
    });
    const areaAnimate={
        offscreen:{x:0, opacity:0},
        onscreen:{
            x:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    return (
    <Layout
        pagina="Restablecer contraseña"
    >
        <main className='w-full h-full bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat'>
            <div className='bg-indigo-800/90 pt-3 w-full h-full pb-10'>
                <h1 className='text-center text-white my-4 text-3xl'>Generar una nueva contraseña</h1>
                <motion.div 
                    className='md:w-1/2 lg:w-1/3 w-10/12 mx-auto pt-10 pb-10 bg-indigo-600 rounded-2xl'
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{once: false, amount: 0.1}}
                    variants={areaAnimate}                
                >
                    { restablecer ? (
                    <form
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="transition-all px-4 w-full pt-5 sm:p-6 mt-5">
                            <div className="sm:items-start px-5">
                                <label htmlFor="password" className="block text-white uppercase font-bold">Nueva Contraseña</label>
                                <input 
                                    className="py-3 px-3 w-full rounded" 
                                    id="password" 
                                    placeholder="Ingresa la nueva contraseña" 
                                    type="password" 
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                { formik.touched.password && formik.errors.password ? (
                                    <div className='w-full block mb-2 text-red-500'>
                                        <p>{formik.errors.password}</p>
                                    </div>
                                ) : null }
                            </div>
                            <div className="sm:items-start mt-5 px-5">
                                <label htmlFor="password2" className="block text-white uppercase font-bold">Repite Contraseña</label>
                                <input 
                                    className="py-3 px-3 w-full rounded" 
                                    id="password2" 
                                    placeholder="Repite la nueva contraseña" 
                                    type="password" 
                                    value={formik.values.password2}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                { formik.touched.password2 && formik.errors.password2 ? (
                                    <div className='w-full block mb-2 text-red-500'>
                                        <p>{formik.errors.password2}</p>
                                    </div>
                                ) : null }
                            </div>
                        </div>
                        <div className="bg-indigo-600 px-4 pt-10 pb-10 sm:px-6">
                            <div className="bg-indigo-600 w-full rounded-lg px-5">
                                <input
                                    type="submit"
                                    className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 hover:cursor-pointer transition-all uppercase font-bold rounded-lg mt-1"
                                    value="Restablecer Contraseña"
                                />
                            </div>
                        </div>
                    </form>

                    ) : (
                        <div>
                            <h2 className='text-center text-white my-4 text-xl'>No hay solicitudes de cambio de contraseña</h2>
                        </div>
                
                     ) }
                </motion.div>
                <div className='md:w-1/2 lg:w-1/3 w-10/12 mx-auto pt-5 pb-10 flex justify-between'>
                    <Link
                        className='block text-white mt-1 mb-10 pb-10 uppercase'
                        to='/'
                    >
                        Volver al inicio
                    </Link>
                    { !restablecer ? (
                    <Link
                        className='block text-white mt-1 mb-10 pb-10 uppercase'
                        to='/recuperarcuenta'
                    >
                        Olvidé mi contraseña
                    </Link>
                    ) : ( null )
                    }
                </div>
           </div>
        </main>
    </Layout>
    )
}

export default RestablecerPassword