import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css/bundle';
import { Url } from './Url';


const Eventos = prop => {
	const [paquetes, setPaquetes] = useState([]);

	const tipo = prop.tipo

	useEffect(() => {
		const Paq = async () => {
			const url = Url + 'api/eventos.php?idtipo=' + tipo;
			const resultado = await axios.get(url);
			console.log(resultado)
			setPaquetes(resultado.data.results);
		}
		Paq();
	}, [])


	let slides = [];

	paquetes && paquetes.map((item, index) => (

		slides.push(
			<SwiperSlide className='h-72' key={index}>
				<Link
					to={'/turismo/' + item.id}
				>
					<img className='object-cover w-full rounded-md' src={Url + item.imagen} alt="img" />
				</Link>
			</SwiperSlide>
		)
	));
	return (
		<Swiper
			spaceBetween={40}
			slidesPerView={1}
			breakpoints={{
				// when window width is >= 320px
				320: {
					slidesPerView: 1,
					spaceBetween: 10
				},
				// when window width is >= 480px
				480: {
					slidesPerView: 2,
					spaceBetween: 20
				},
				// when window width is >= 640px
				800: {
					slidesPerView: 3,
					spaceBetween: 20
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 30
				}

			}}
			observer={true}
			centeredSlides={true}
			autoplay={{
				delay: 4500,
				disableOnInteraction: false,
			}}

			modules={[Autoplay, Pagination, Navigation]}

			speed={500}
			loop={true}
			touchRatio={1.5}
			effect={"fade"}
			className="mySwiper"
		>
			{slides}
		</Swiper>
	);
}

export default Eventos