import { 
    
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    LIMPIAR_ALERTA,
    RESTABLECER_REGISTRO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    USUARIO_AUTENTICADO,
    CERRAR_SESION
 } from "../../types";


export default (state, action) => {
    switch(action.type) {

        case REGISTRO_EXITOSO:
            return {
                ...state,
                mensaje: action.payload,
                registradoOk: true
            }
        case REGISTRO_ERROR:
        case LOGIN_ERROR:
            return {
                ...state,
                mensaje: action.payload
            }
        case LOGIN_EXITOSO:
            localStorage.setItem('ld_token',action.payload.token)
            localStorage.setItem('mail',action.payload.mail)
            localStorage.setItem('ld_tip',action.payload.tipo)
            return {
                ...state,
                token: action.payload,
                autenticado: true
            }
        
        case LIMPIAR_ALERTA:
            return {
                ...state,
                mensaje: null
            }
        case RESTABLECER_REGISTRO:
            return {
                ...state,
                registradoOK: false
            }
        case USUARIO_AUTENTICADO:
            return {
                ...state,
                usuario:action.payload
            }
        case CERRAR_SESION:
            localStorage.removeItem('ld_token');
            localStorage.removeItem('mail');
            localStorage.removeItem('ld_tip');
            return {
                ...state,
                usuario: null,
                token: null,
                autenticado: null,
                nivel: null
            }
        default: 
            return state;
    }
}