import Layout from '../components/Layout'
import SliderPrincipal from '../components/SliderPrincipal'
import Turismos from '../components/Turismos'
import Paquetes from '../components/Paquetes'
import Eventos from '../components/Eventos'
import imgExterna from '../assets/Rentourist_logofinal_png01.png'
import { motion } from 'framer-motion';
import { areaAnimateLR, areaAnimateRL, areaAnimateS } from '../components/Animaciones';
import Countdown from 'react-countdown';

export default function Home() {

	const fechaActual = new Date();
	const miliSA = fechaActual.valueOf();

	const fechaEvento = new Date(2024, 8, 1);


	const miliSF = fechaEvento.valueOf();

	const miliSR = miliSF - miliSA;

	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			return (
				<div className='w-full md:flex mx-auto mt-2'>
					<Eventos tipo={13} />
				</div>
			);
		} else {
			// Render a countdown
			return (
				<div className='w-full py-10'>
					<div className='w-10/12 mx-auto rounded-3xl bg-orange-600 py-32 text-orange-300 text-center text-5xl'>
						<p className='mb-10'>Faltan</p>
						<span>{days} dias {hours} horas {minutes} minutos y {seconds} segundos</span>

					</div>
				</div>
			);
		}
	};

	return (
		<Layout
			pagina=""
		>
			<main className='h-full'>
				<div className='w-full h-full bg-black'>
					<SliderPrincipal />
					<div>
						<Countdown

							date={Date.now() + miliSR}
							renderer={renderer}
						/>
					</div>
					<motion.h3
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateRL}
						className='text-white text-5xl text-center my-10'>Turismo internacional</motion.h3>
					<div className='w-full md:flex mx-auto mt-2'>
						<Eventos tipo={7} />
					</div>
					<motion.h3
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateRL}
						className='text-white text-5xl text-center my-10'>Turismo Nacional</motion.h3>
					<div className='w-full md:flex mx-auto mt-2 mb-24'>
						<Eventos tipo={10} />
					</div>
					<div className='w-11/12 md:flex bg-black mx-auto'>
						<div className='w-full md:w-1/4 h-96 p-3'>
							<Turismos
								idservicio={1}
								lnk={'estudiantil'}
							/>
						</div>
						<div className='w-full md:w-1/4 h-96 p-3'>
							<Turismos
								idservicio={2}
								lnk={'grupal'}
							/>
						</div>
						<div className='w-full md:w-1/4 h-96 p-3'>
							<Turismos
								idservicio={3}
								lnk={'nacional'}
							/>
						</div>
						<div className='w-full md:w-1/4 h-96 p-3'>
							<Turismos
								idservicio={4}
								lnk={'internacional'}
							/>
						</div>
					</div>
					<div className='w-full flex pb-24'>
						<Paquetes />
					</div>
					<div className='w-full pb-24'>
						<h3 className='text-white text-center text-4xl'>¿Necesitas algo para tu viaje?</h3>
						<p className='text-white text-center text-3xl mt-5'>Encontrá lo que buscas en</p>
						<a
							href={"https://www.rentourist.com.ar"}
							target='_blank'
						>
							<img className="rounded-xl w-9/12 md:w-1/4 mx-auto object-cover mt-10" src={imgExterna} alt="img"></img>
						</a>
					</div>

				</div>
			</main>

		</Layout>
	)
}
