import React, { useEffect, useContext } from 'react';
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from 'axios';
import appContext from '../context/auth/appContext';
import { Url } from './Url';

const FormularioConsulta = () => {
    const mail = localStorage.getItem('mail');
    const { openConsulta, setOpenConsulta } = useContext(appContext)

    useEffect(() => {
        const datosCliente = async () => {
            const formData = new FormData()
            formData.append('mail', mail);
            try {
                const response = await axios({
                    url: Url + 'api/datoscliente.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response.data.results);
                formik.setFieldValue('nombre', response.data.results.nombre);
                formik.setFieldValue('apellido', response.data.results.apellido);
                formik.setFieldValue('telefono', response.data.results.telefono);
                formik.setFieldValue('mail', mail);
            } catch (e) {
                //console.log(e)
            }
        }
        if (mail) {
            datosCliente();
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            nombre: '',
            apellido: '',
            telefono: '',
            mail: '',
            consulta: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .min(3, 'El nombre es muy corto')
                .required('El nombre es obligatorio'),
            apellido: Yup.string()
                .min(3, 'El apellido es muy corto')
                .required('El apellido es obligatorio'),
            telefono: Yup.number()
                .integer('El número no es válido')
                .positive('El número no es válido')
                .typeError('El número no es válido')
                .min(10, 'El número es demasiado corto')
                .required('El teléfono es obligatorio'),
            mail: Yup.string()
                .email('Email no válido')
                .required('El email es obligatorio'),
            consulta: Yup.string()
                .min(10, 'Por favor escriba una consulta válida')
                .required('El campo Consulta es obligatorio'),
        }),
        onSubmit: async (valores, { resetForm }) => {
            //console.log(paquete);
            const formData = new FormData()
            formData.append('nombre', valores.nombre);
            formData.append('apellido', valores.apellido);
            formData.append('telefono', valores.telefono);
            formData.append('mail', valores.mail);
            formData.append('consulta', valores.consulta);
            try {
                const response = await axios({
                    url: Url + 'api/nuevaconsulta.php',
                    method: 'POST',
                    data: formData,
                })
                setOpenConsulta(false)
            } catch (e) {
                console.log(e)
            }
        }
    });


    return (
        <div className="w-full">
            <h2 className="text-3xl text-center text-white font-bold uppercase">Formulario de Consulta</h2>
            <form
                onSubmit={formik.handleSubmit}
            >
                <>
                    <div className="transition-all px-4 w-full pt-5 sm:p-6">
                        <div className="sm:items-start mt-5">
                            <label htmlFor="apellido" className="block text-white uppercase font-bold">Apellido</label>
                            <input
                                type="text"
                                className="py-3 px-3 w-full rounded"
                                id="apellido"
                                placeholder="Ingrese su Apellido"
                                value={formik.values.apellido}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.apellido && formik.errors.apellido ? (
                                <div className='bg-red-600 text-center uppercase text-white rounded'>
                                    <p>{formik.errors.apellido}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="nombre" className="block text-white uppercase font-bold">Nombre</label>
                            <input
                                type="text"
                                className="py-3 px-3 w-full rounded"
                                id="nombre"
                                placeholder="Ingrese su Nombre"
                                value={formik.values.nombre}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.nombre && formik.errors.nombre ? (
                                <div className='bg-red-600 text-center uppercase text-white rounded'>
                                    <p>{formik.errors.nombre}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="telefono" className="block text-white uppercase font-bold">Nº de Teléfono Celular, Sin 0 y sin 15 (Ej. 3815123456)</label>
                            <input
                                type="tel"
                                className="py-3 px-3 w-full rounded"
                                id="telefono"
                                placeholder="Nº de tel. Celular"
                                value={formik.values.telefono}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.telefono && formik.errors.telefono ? (
                                <div className='bg-red-600 text-center uppercase text-white rounded'>
                                    <p>{formik.errors.telefono}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="mail" className="block text-white uppercase font-bold">E-mail</label>
                            <input
                                type="mail"
                                className="py-3 px-3 w-full rounded"
                                id="mail"
                                placeholder="Ingrese su E-mail"
                                value={formik.values.mail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.mail && formik.errors.mail ? (
                                <div className='bg-red-600 text-center uppercase text-white rounded'>
                                    <p>{formik.errors.mail}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="consulta" className="block text-white uppercase font-bold">Consulta</label>
                            <textarea
                                type="text"
                                className="py-3 px-3 w-full rounded"
                                id="consulta"
                                placeholder="Ingrese su consulta"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            >
                                {formik.values.password}
                            </textarea>
                            {formik.touched.consulta && formik.errors.consulta ? (
                                <div className='bg-red-600 text-center uppercase text-white rounded'>
                                    <p>{formik.errors.consulta}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="px-4 pb-10 sm:px-6">
                        <div className="w-full rounded-lg">
                            <input
                                type="submit"
                                className="w-full shadow-md p-3 bg-indigo-600 text-gray-100 hover:text-gray-300 hover:cursor-pointer transition-all uppercase font-bold rounded-lg mt-1"
                                value="Enviar Consulta"
                            />
                            <button
                                type="button"
                                className="w-full shadow-sm p-3 bg-gray-500 hover:bg-gray-400 transition-all text-white uppercase font-bold rounded-xl mt-1"
                                onClick={() => setOpenConsulta(false)}
                            >
                                Cancelar
                            </button>

                        </div>
                    </div>
                </>
            </form>

        </div>
    )
}

export default FormularioConsulta