import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMercadopago } from 'react-sdk-mercadopago';
import { motion } from 'framer-motion';
import { Url } from './Url';



const ListadoCuenta = () => {
    const [cuentas, setCuentas] = useState([]);
    const [registros, setRegistros] = useState([]);
    const [preferenceId, setPreferenceId] = useState(null);
    const mp = useMercadopago.v2('TEST-56db20ae-073e-4cb5-86a9-f6f586fdb75c', {
        locale: 'es-AR'
    });
    const [preferencia, setPreferencia] = useState(false);
    const [rendered, setRendered] = useState(false);

    //const [ count, setCount ] = useState(0);
    const mail = localStorage.getItem('mail');

    const areaAnimate = {
        offscreen: { x: 0, opacity: 0 },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    const cta = async () => {
        const url = `${Url}api/listadocuentas.php?mail=${mail}`;
        const resultado = await axios.get(url);
        //console.log(resultado)
        setCuentas(resultado.data.results);
    }

    useEffect(() => {
        cta();
    }, [])


    const handleCheckBox = (e) => {
        //console.log(e.target.id)
        let selectedRegistros = [...registros];
        if (e.target.checked)
            selectedRegistros.push(e.target.value);
        else
            selectedRegistros.splice(selectedRegistros.indexOf(e.target.value), 1);

        setRegistros(selectedRegistros);
        /*setCount(count+1)
        console.log(`Count: `,count)*/
    }
    const addCheckout = () => {
        // Inicializa el checkout Aca seria la linea 20
        if (mp && !rendered) {
            mp.checkout({
                preference: {
                    id: preferenceId,
                },
                render: {
                    container: '.cho-container', // Indica el nombre de la clase donde se mostrará el botón de pago
                    label: 'Realizar Pago', // Cambia el texto del botón de pago (opcional)
                },
            });
            setRendered(true);
        }
    }

    useEffect(() => {
        // luego de montarse el componente, le pedimos al backend el preferenceId
        const generarPago = async () => {
            const formData = new FormData()
            formData.append('conceptos', JSON.stringify(registros));
            try {
                const results = await axios({
                    url: Url + 'api/pago.php',
                    method: 'POST',
                    data: formData,
                })
                setPreferencia(true);
                console.log(results)

                setPreferenceId(results.data.results.preferenceId);
            } catch (e) {
                console.log(e)
            }
        }
        if (registros.length)
            generarPago()
    }, [registros]);

    useEffect(() => {
        if (preferenceId) {
            // con el preferenceId en mano, inyectamos el script de mercadoPago
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://sdk.mercadopago.com/js/v2';
            script.addEventListener('load', addCheckout); // Cuando cargue el script, se ejecutará la función addCheckout
            let contenedor = document.getElementById('contenedorPago');
            contenedor.appendChild(script);
        }
    }, [preferenceId]);

    return (
        <motion.div className='bg-orange-500 rounded-2xl p-3'
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.1 }}
            variants={areaAnimate}
        >
            <table className='table-auto border-collapse border-spacing-5 border border-slate-400 text-white rounded-xl mx-auto' >
                <thead >
                    <tr>
                        <th className='border border-slate-300 p-2'></th>
                        <th className='border border-slate-300 p-2'>Plan Nº</th>
                        <th className='border border-slate-300 p-2'>Nº de Cuota</th>
                        <th className='border border-slate-300 p-2'>Monto</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof cuentas !== 'undefined' ? (
                            cuentas.map((cuenta, index) => (
                                <tr key={index}>
                                    <td className='border border-slate-300 p-2'>
                                        <input
                                            type="checkbox"
                                            id={cuenta.id}
                                            value={cuenta.id}
                                            onChange={(e) => handleCheckBox(e)}
                                            checked={registros.includes(cuenta.id)}
                                        />
                                    </td>
                                    <td className='border border-slate-300 p-2'>{cuenta.numero}</td>
                                    <td className='border border-slate-300 p-2'>{cuenta.cuota}</td>
                                    <td className='border border-slate-300 p-2'>{cuenta.monto}</td>
                                </tr>

                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
            {
                preferenceId ? (
                    <div className='w-1/12 mx-auto mt-5'>
                        <div>
                            <div id='contenedorPago' className="cho-container"></div>
                        </div>
                    </div>
                ) : null
            }
        </motion.div>
    );
}

export default ListadoCuenta