import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ImagenesHotel from './ImagenesHotel';
import { motion } from 'framer-motion';
import { Url } from './Url';
import { areaAnimateDU, areaAnimateLR, areaAnimateRL, areaAnimateS } from './Animaciones';


const PaqueteHotel = ({ idpaquete }) => {
    const [hoteles, setHoteles] = useState([]);

    useEffect(() => {
        const ho = async () => {
            const url = Url + 'api/paquetehotel.php?idpaquete=' + idpaquete;
            const resultado = await axios.get(url);
            //console.log(resultado)
            setHoteles(resultado.data.results);
        }
        ho();
    }, [])

    return (
        <>
            {
                typeof hoteles!=='undefined' && hoteles.map((hotel, index) => (
                    <motion.div className="w-full" key={index}
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{ once: true, amount: 0.1 }}
                        variants={areaAnimateS}
                    >
                        <motion.div
                            className='w-full pt-3 pb-3'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{ once: true, amount: 0.1 }}
                            variants={areaAnimateLR}
                        >
                            <h1 className='text-orange-400 text-3xl text-left font-semibold font-roboto'>{hotel.nombrehotel}</h1>
                            <h3 className='text-orange-400 text-xl font-roboto'>{hotel.descripcion}</h3>
                        </motion.div>
                        <motion.div
                            className='w-full mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{ once: true, amount: 0.1 }}
                            variants={areaAnimateLR}
                        >
                            <ImagenesHotel
                                idhotel={hotel.id}
                            />
                        </motion.div>
                    </motion.div>

                ))
            }
        </>
    );
}

export default PaqueteHotel