import Layout from '../components/Layout'
import { motion } from 'framer-motion';
import { useFormik } from "formik"
import * as Yup from "yup"
import { Link } from "react-router-dom";
import axios from 'axios';
import { Url } from '../components/Url';
import { useState } from 'react';
import Mensaje from '../components/Mensaje';
//import Alerta from '../components/Alerta';


const RecuperarCuenta = () => {
    const [ enviado, setEnviado ] = useState(false)
    const [ error, setError ] = useState(false)

    const formik = useFormik({
        initialValues:{
            mail: ''
        },
        validationSchema: Yup.object({
            mail: Yup.string()
                    .required("El email es obligatorio")
                    .email("El email no es correcto")
        }),
        onSubmit: async valores => {
            const formData=new FormData()
            formData.append('mail',valores.mail);
            try{
                const response = await axios({
                    url: Url+'api/recuperarcuenta.php',
                    method: 'POST',
                    data: formData,
                })
                setEnviado(true)
                setTimeout(() => { 
                    setEnviado(false)
                }, 10000)
        
            } catch (e) {
                setError(true);
                setTimeout(() => { 
                    setError(false)
                }, 5000)
            }
        }
    });
    const areaAnimate={
        offscreen:{x:0, opacity:0},
        onscreen:{
            x:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    return (
    <Layout
        pagina="Turismo Estudiantil"
    >
        <main className='w-full h-full bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat'>
            <div className='bg-indigo-800/90 pt-3 w-full h-full pb-10'>
                <h1 className='text-center text-white my-4 text-3xl'>Recupera el acceso a tu cuenta</h1>
                { enviado ? 
                        <Mensaje 
                            mensaje="Por favor, verifique su casilla de mail para restablecer la contraseña"
                        /> : null }
                    {
                        error ?
                        <Mensaje 
                            mensaje="El e-mail ingresado no corresponde a un usuario registrado"
                        /> : null
                }
                <motion.div 
                    className='md:w-1/2 lg:w-1/3 w-10/12 mx-auto pt-10 pb-10 bg-indigo-600 rounded-2xl'
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{once: false, amount: 0.1}}
                    variants={areaAnimate}                
                >
                <form
                    onSubmit={formik.handleSubmit}
                >
                    <div className="transition-all px-4 w-full pt-5 sm:p-6 mt-5">
                        <div className="sm:items-start px-5">
                            <label htmlFor="mail" className="block text-white uppercase font-bold">E-mail</label>
                            <input 
                                className="py-3 px-3 w-full rounded" 
                                id="mail" 
                                placeholder="E-mail de recuperación" 
                                type="text" 
                                value={formik.values.mail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.mail && formik.errors.mail ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.mail}</p>
                                </div>
                            ) : null }
                        </div>
                    </div>
                    <div className="bg-indigo-600 px-4 pt-10 pb-5 sm:px-6">
                        <div className="bg-indigo-600 w-full rounded-lg px-5">
                            <input
                                type="submit"
                                className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-1"
                                value="Solicitar Cambio de Contraseña"
                            />
                        </div>
                    </div>
                </form>
                </motion.div>
                <div className='md:w-1/2 lg:w-1/3 w-10/12 mx-auto pt-2 pb-10 flex justify-between'>
                    <Link
                        className='block text-white mt-1 uppercase'
                        to='/'
                    >
                        Volver al inicio
                    </Link>
                </div>
           </div>
        </main>
    </Layout>
    )
}

export default RecuperarCuenta