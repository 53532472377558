import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios';
import { motion } from 'framer-motion';
import { Url } from '../Url';
import {FaWhatsapp} from 'react-icons/fa'
import {AiOutlineDelete} from 'react-icons/ai'
import { ImAttachment } from 'react-icons/im'
import { useFormik } from "formik"
import * as Yup from "yup"

const ListadoConsultas = () => {
    const [ consultas, setConsultas ] = useState([]);
    const [ consulta, setConsulta ] = useState([]);
    const [ vistaRespuesta, setVistaRespuesta ] = useState(0);
    const [ mailRespuesta, setMailRespuesta ] = useState("");
    const [ idConsulta, setIdConsulta ] = useState(0);
    const [ open, setOpen ] = useState(false)
    const [file, setFile] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);

    function uploadSingleFile(e) {
        let ImagesArray = Object.entries(e.target.files).map((e) =>
            URL.createObjectURL(e[1])
        );
        setFile([...file, ...ImagesArray]);
        let rData=[...selectedFile];
        rData.push(e.target.files[0])
        setSelectedFile(rData)
    }

    const cancelButtonRef = useRef(null)

    const consultarConsultas = async () => {
        const formData=new FormData()
        
        try{
          const response = await axios({
            url: Url+'api/listadoconsultas.php',
            method: 'POST',
            data: formData,
          })
          //console.log(response.data.results)
          setConsultas(response.data.results)
        } catch (e) {
          console.log(e)
        }
    }
    useEffect(() => {
        consultarConsultas();
    }, [])

    const areaAnimate={
        offscreen:{x:0, opacity:0},
        onscreen:{
            x:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    const handleClick = async (id) => {
        const url = `${Url}api/verconsulta.php?id=${id}`;
        //console.log(url);
        const resultado = await axios.get(url);
        //console.log(resultado)
        setConsulta(resultado.data.results);
        //let vectorConsultas = [...consultas];
        let rData=[...consultas];
        for(let data of rData){
            if(data.id===id){
                data.visto=1;
                setMailRespuesta(data.mail);
                setIdConsulta(data.id);
                break;
            }
        }    
        setConsultas(rData);
        setVistaRespuesta(0);
        setOpen(true);
    }
    const formik = useFormik({
        initialValues:{
            respuesta: ''
        },
        validationSchema: Yup.object({
            respuesta: Yup.string()
                    .required("Debe ingresar una respuesta")
        }),
        onSubmit: async (valores,{resetForm}) => {            
            const formData=new FormData()
            selectedFile.forEach(file=>{
                formData.append('my_file[]', file);
              });
            formData.append('mail',mailRespuesta);
            formData.append('respuesta',valores.respuesta);
            formData.append('idconsulta',idConsulta);
            try{
                const response = await axios({
                    url: Url+'api/resp.php',
                    method: 'POST',
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                console.log(response)

            let rData=[...consulta];
            for(let data of rData){
                if(data.id===idConsulta){
                    data.respuesta=1;
                    break;
                }
            }    
            setConsulta(rData);            
            setOpen(false);
            } catch (e) {
                //console.log(e)
            }
            resetForm({ values: ''});
        }
    });
    const eliminarConsulta = async () => {
        const formData=new FormData()
        formData.append('idconsulta',idConsulta);
        try{
            const response = await axios({
                url: Url+'api/eliminarconsulta.php',
                method: 'POST',
                data: formData,
            })
        console.log(response.data.results)
        setConsulta([]);
        consultarConsultas()
        } catch (e) {
            console.log(e)
        }

    }

    return (
        <>
        <motion.div className='bg-white rounded-2xl p-3'
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{once:true, amount: 0.1}}
            variants={areaAnimate}                    
        >
            <h3 className='text-gray-600 text-2xl text-center mb-5'>Consultas</h3>
            <table className = 'table-auto border-collapse border-spacing-5 border border-gray-300 text-gray-600 rounded-xl mx-auto' >
                <thead >
                    <tr className='border border-gray-300 p-2 w-full'>
                        <th className='p-2 w-1/5'>Cliente</th>
                        <th className='p-2 w-3/5'>Consulta</th>
                        <th className='p-2 w-1/5'>Fecha/Hora</th> 
                    </tr>
                </thead>
                <tbody >
                {
                    typeof consultas !== 'undefined' ? (
                        consultas.map((item) => (
                         item.visto==="0" ? (
                        <tr 
                            className='border border-gray-300 font-bold hover:cursor-pointer' 
                            key={item.id}
                            onClick={()=> handleClick(item.id)}
                        >
                            <td className='p-2'>{`${item.apellido}, ${item.nombre}`}</td>
                            <td className='p-2'>{`${item.consulta}`}</td>
                            <td className='p-2'>{`${item.tiempo}`}</td>
                        </tr>) : (
                        <tr 
                            className='border border-gray-300 bg-blue-100 hover:cursor-pointer' 
                            key={item.id}
                            onClick={()=> handleClick(item.id)}
                        >
                            <td className='p-2'>{`${item.apellido}, ${item.nombre}`}</td>
                            <td className='p-2'>{`${item.consulta}`}</td>
                            <td className='p-2'>{`${item.tiempo}`}</td>
                        </tr>
                        )
                        ))
                    ) : ( null )
                }
                </tbody>
            </table>
        </motion.div>
            
            <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-10/12">
                    { vistaRespuesta===0 ? (
                        typeof consulta !== 'undefined' && (
                        consulta.map((item) => (
                        <div className="bg-white rounded-2xl p-3 mt-5 text-gray-600"
                            key={item.id}
                        >
                            <div className='px-10'>
                                <div className='flex justify-between'>
                                    <p>{`${item.apellido}, ${item.nombre}`}</p>
                                    <p>{`${item.fecha} - ${item.hora}`}</p>
                                </div>
                                <div className='flex justify-between'>
                                    <p className='font-bold'>{`${item.mail}`}</p>
                                </div>
                            </div>
                            <hr />
                            <div className='p-10'>
                                <p>{item.consulta}</p>
                                <hr className='mt-10'/>
                               
                                <div className={  item.respuesta==="0" ? 'bg-red-100 p-5' : 'bg-green-100 p-5'}>
                                    <h3 className='text-xl font-bold'>{item.respuesta==="0" ? "Aún no se respondió ésta consulta" : "Ya se envió una respuesta a ésta consulta"}</h3>
                                    <div className='mt-1 flex'>
                                        <a
                                            className="flex items-center justify-center md:justify-start bg-gray-400 text-white p-3 rounded"
                                            href={`https://wa.me/${item.telefono}`}
                                            target={"_blank"}
                                            rel='noreferrer'
                                        >
                                            <FaWhatsapp className="mr-1" aria-hidden="true" />
                                            {item.telefono}
                                        </a>                                
                                        <button
                                            className="flex items-center justify-center md:justify-start bg-gray-400 text-white ml-10 p-3 rounded"
                                            onClick={() => setVistaRespuesta(1)}
                                        >
                                            Responder por Email
                                        </button>
                                        <button
                                            className="flex items-center justify-center md:justify-start bg-red-400 text-white ml-10 p-3 rounded"
                                            onClick={() => eliminarConsulta()}
                                        >
                                            <AiOutlineDelete className="mr-1" aria-hidden="true" />
                                            Eliminar Consulta
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        )))
                    ): (
                        <form
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="bg-white px-4 pt-5 sm:p-6">
                                <div className="sm:items-start px-6">
                                    <h3 className='text-gray-600 text-xl text-center uppercase font-bold'>Respuesta</h3>
                                    <textarea
                                        className='w-full h-72 border border-blue-600 rounded-xl mt-5'
                                        id="respuesta"
                                        value={formik.values.respuesta}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}   
                                        autoFocus                                 
                                    >
                                    </textarea>                            
                                    { formik.touched.respuesta && formik.errors.respuesta ? (
                                        <div className='w-full block mb-2 text-red-500'>
                                            <p>{formik.errors.respuesta}</p>
                                        </div>
                                    ) : null }
                                </div>
                            </div>
                            <div className='px-11 mb-5'>
                                <label className='flex flex-col justify-center items-center w-full h-32 bg-gray-200 rounded-lg hover:cursor-pointer text-gray-600' htmlFor="archivoAdjunto">
                                    <div className='text-3xl'>
                                        <ImAttachment />
                                    </div>
                                    <p className='text-center'>Adjuntar Archivo</p>
                                    <input
                                        type="file"
                                        id="archivoAdjunto"
                                        disabled={file.length === 5}
                                        onChange={uploadSingleFile}
                                    />
                                </label>
                            </div>
                            <div className="bg-white px-4 pb-10 sm:px-6">
                                <div className="bg-white w-full rounded-lg px-5">
                                    <input
                                        type="submit"
                                        className="w-full shadow-sm p-3 bg-blue-500 hover:bg-gray-400 transition-all text-white uppercase font-bold rounded-xl mt-1"
                                        value="Enviar Respuesta"
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                        <div className="bg-white px-4 pb-10 sm:px-6">
                            <div className="bg-white w-full rounded-lg px-5">
                                <button
                                    type="button"
                                    className="w-full shadow-sm p-3 bg-gray-500 hover:bg-gray-400 transition-all text-white uppercase font-bold rounded-xl mt-1"
                                    onClick={() => setOpen(false)}
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
            </Transition.Root>

        </>
  )
}

export default ListadoConsultas