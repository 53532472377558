import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Layout from '../components/Layout';
import { areaAnimateLR, areaAnimateRL, areaAnimateS } from '../components/Animaciones';
import NuevaReserva from '../components/NuevaReserva';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom'
import { Url } from '../components/Url';
import appContext from '../context/auth/appContext';


const Reserva = () => {
    const [ reserva, setReserva ] = useState([]);
    const { setColegio, setCurso, setPaquete } = useContext(appContext);
    
    useEffect(() => {
        const tur = async () => {
            const url = Url+'api/paquetecurso.php?id='+pq.id;
            const resultado = await axios.get(url);
            setPaquete(resultado.data.results.id)
            setColegio(resultado.data.results.idcolegio)
            setCurso(resultado.data.results.idcurso)
            setReserva(resultado.data.results);
        }
        tur();
    }, [])
    
    let pq=useParams()

    return (
        <Layout
            pagina="Reserva"
        >
            <main className='w-full h-full bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat'>
                <div className='bg-indigo-800/90 w-full h-full pb-10 font-roboto'>
                    <article className='container mx-auto'>
                        <motion.div 
                            className='w-full mx-auto pt-5 mb-10 relative'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{once:true, amount: 0.1}}
                            variants={areaAnimateLR}
                        >
                            <h1 className='text-center text-orange-600 text-5xl mt-10 font-semibold uppercase'>Formulario de Reserva</h1>
                            <motion.div
                                className='mt-10 w-full p-10 bg-white/10 rounded-xl'
                            >
                                <h3 className='text-center text-orange-500 text-3xl font-semibold uppercase'>Descripción</h3>
                                <p className='text-orange-500 text-2xl font-semibold'>Paquete: {reserva.paquete}</p>
                                <p className='text-orange-500 text-2xl font-semibold'>Duración: {reserva.duracion}</p>
                            </motion.div>
                            <motion.div
                                className='mt-2 w-full p-10 bg-white/10 rounded-xl'
                            >
                                <h3 className='text-center text-orange-500 text-3xl font-semibold uppercase'>Datos del Curso</h3>
                                <p className='text-orange-500 text-2xl font-semibold'>Colegio: {reserva.nombre}</p>
                                <p className='text-orange-500 text-2xl font-semibold'>Curso: {`${reserva.grado}º ${reserva.division} - ${reserva.turno}`}</p>
                            </motion.div>
                        </motion.div>
                        <NuevaReserva tipo={2}/>
                    </article>
                </div>
            </main>
        </Layout>
    )
}

export default Reserva