import Layout from '../components/Layout'
import ModalForm from '../components/ModalForm'
import Turismos from '../components/Turismos';
import ListadoPromoDestinos from '../components/ListadoPromoDestinos'
import toast, { Toaster } from 'react-hot-toast';

const Promociones = () => {
    
    const notify = (data) => {
        if (data===0)
            toast('Consulta enviada con éxito!')
    };

    return (
        <Layout
            pagina="Promociones"
        >
            <main className='w-full h-full bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat'>
                <Toaster />
                <div className='bg-indigo-800/90 w-full h-full pb-10'>
                    <div className='w-full h-70 bg-black'>
                        <Turismos
                            idservicio={6}
                        />
                    </div>
                    <div className='w-full mt-5 h-70'>
                        <ListadoPromoDestinos />
                    </div>
                    <div className='md:container mx-auto mt-10 mb-5'>
                        <ModalForm 
                            func={notify}
                        />
                    </div>
                </div>
            </main>
            
        </Layout>
  )
}

export default Promociones