import React, { useState } from 'react';
import Layout from '../components/Layout'
import ListadoCuenta from '../components/ListadoCuenta';


const Cuentas = () => {
    return (
        <Layout
            pagina="Mis Cuentas"
        >
            <main className='w-full h-full bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat'>
                <div className='bg-indigo-800/90 w-full h-full pb-40 pt-2 mt-0'>
                    <h1 className='mt-5 text-white text-5xl text-center'>Mis cuentas</h1>
                    <ListadoCuenta />
                </div>
            </main>
        </Layout>
  )
}

export default Cuentas