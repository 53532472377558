import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios';
import { motion } from 'framer-motion';
import { Url } from '../Url';


const ListadoReservasNuevas = () => {
    const [ reservas, setReservas ] = useState([]);
    const [ reserva, setReserva ] = useState({});
    const [ colegios, setColegios ] = useState([]);
    const [ colegio, setColegio ] = useState(0);
    const [ cursos, setCursos ] = useState([]);
    const [ curso, setCurso ] = useState(0);
    const [ tipoReserva, setTipoReserva ] = useState("0");
    const [ open, setOpen ] = useState(false);
    const cancelButtonRef = useRef(null)

    useEffect(() => {
        const consultarReservas = async () => {
            console.log('colegio: ',colegio,'..curso: ',curso)
            const formData=new FormData()
            formData.append('colegio',colegio);
            formData.append('curso',curso);
            formData.append('visto',tipoReserva);
            try{
              const response = await axios({
                url: Url+'api/listadoreservasnuevas.php',
                method: 'POST',
                data: formData,
              })
    
              console.log(response)
              setReservas(response.data.results)
            } catch (e) {
              console.log(e)
            }
        }    
        consultarReservas();
    }, [colegio,curso,tipoReserva])

    useEffect(() => {
        const Colegios = async () => {
            const url = Url+'api/listadocolegios.php';
            const resultado = await axios.get(url);
            setColegios(resultado.data.results);
        }
        Colegios();
    }, [])

    useEffect(() => {
        const consultarCursos = async () => {
            const formData=new FormData()
            formData.append('colegio',colegio);
            try{
              const response = await axios({
                url: Url+'api/consultarcursos.php',
                method: 'POST',
                data: formData,
              })
              setCursos(response.data.results)
            } catch (e) {
              console.log(e)
            }
        }
        if(typeof curso !== "undefined")
        {
            consultarCursos();
        }
    }, [colegio])

    let listadoColegios=colegios.map(colegio => (
        <option key={colegio.id} value={colegio.id}>{colegio.nombre}</option>
    ))

    const areaAnimate={
        offscreen:{x:0, opacity:0},
        onscreen:{
            x:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    } 

    const handleClick = async (id) => {
        const url = `${Url}api/verreserva.php?id=${id}`;
        //console.log(url);
        const resultado = await axios.get(url);
        console.log(resultado.data.results)
        setReserva({
            "fechacontrato" :resultado.data.results[0].fechacontrato,
            "apellido" :resultado.data.results[0].apellido,
            "nombre" :resultado.data.results[0].nombre,
            "documento" :resultado.data.results[0].documento,
            "fechanacimiento" :resultado.data.results[0].fechanacimiento,
            "apellidotutor" :resultado.data.results[0].apellidotutor,
            "nombretutor" :resultado.data.results[0].nombretutor,
            "documentotutor" :resultado.data.results[0].documentotutor,
            "datoscolegio" :resultado.data.results[0].datoscolegio,
            "nombrepaquete" :resultado.data.results[0].nombrepaquete,
            "duracion" :resultado.data.results[0].duracion,
            "tipopago" :resultado.data.results[0].tipopago,
            "imagen" :resultado.data.results[0].imagen
        });
        setOpen(true);
        let rData=[...reservas];
        for(let data of rData){
            if(data.id===id){
                data.visto=1;
                break;
            }
        }    
        setReservas(rData);
    }





    return (
        <motion.div className='p-3'
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{once:true, amount: 0.1}}
            variants={areaAnimate}                    
        >
            <div className='bg-white rounded-2xl py-10'>
            <div className='flex w-full'>
                <div className='md:w-1/3 w-full text-gray-600 pl-9'>
                    <div className='w-full mt-2'>
                        <input 
                            type="radio"
                            id="nuevas"
                            value="0"
                            name="tiporeserva"
                            className='mr-2'
                            onChange={(e) => setTipoReserva( e.target.value )}
                            checked={tipoReserva==="0" ? true : false }
                        />
                        <label htmlFor='nuevas'>Nuevas reservas</label>
                    </div>
                    <div className='w-full mt-2'>
                        <input 
                            type="radio"
                            id="leidas"
                            value="1"
                            name="tiporeserva"
                            className='mr-2'
                            onChange={(e) => setTipoReserva( e.target.value )}
                            checked={tipoReserva==="1" ? true : false }
                        />
                        <label htmlFor='leidas'>Reservas vistas</label>                        
                    </div>
                    <div className='w-full mt-2'>
                        <input 
                            type="radio"
                            id="todas"
                            value="2"
                            name="tiporeserva"
                            className='mr-2'
                            onChange={(e) => setTipoReserva( e.target.value )}
                            checked={tipoReserva==="2" ? true : false }
                        />
                        <label htmlFor='todas'>Todas las reservas</label>                        
                    </div>
                </div>
                <div className='md:w-1/3 w-full text-gray-600 pl-9'>
                    <div className='w-full'>
                        <select className='w-full p-2 rounded bg-white text-gray-700' id='sColegio' onChange={(e) => setColegio( e.target.value )}>{listadoColegios}</select>
                    </div>
                    <div className='w-full'>
                        {
                            colegio!=="0" ? (
                            <select className='w-full p-2 rounded bg-white text-gray-700' id='sCurso' onChange={(e) => setCurso( e.target.value )}>
                                { typeof cursos !== "undefined" ? (
                                    cursos.map(item => (
                                        <option key={item.id} value={item.id}>{item.detalle}</option>))
                                ) : null }
                            </select>
    
                            ) : null
                        }
                    </div>
                </div>
                <div className='md:w-1/3 w-full text-gray-600 pl-9'>
                    
                </div>
            </div>
            <hr/>
            <h3 className='text-gray-600 text-2xl text-center mb-5'>Reservas</h3>
            <table className = 'table-auto border-collapse border-spacing-5 border border-gray-500 text-gray-600 rounded-xl mx-auto' >
                <thead >
                    <tr>
                        <th className='border border-gray-500 p-2'>Fecha de Reserva</th> 
                        <th className='border border-gray-500 p-2'>Apellido</th>
                        <th className='border border-gray-500 p-2'>Nombre</th>
                        <th className='border border-gray-500 p-2'>Documento</th>
                        <th className='border border-gray-500 p-2'>Domicilio</th>
                        <th className='border border-gray-500 p-2'>Teléfono</th>
                        <th className='border border-gray-500 p-2'>Colegio / Curso</th>
                        <th className='border border-gray-500 p-2'>Paquete</th>
                    </tr>
                </thead>
                <tbody >
                {
                    typeof reservas !== 'undefined' && (
                        reservas.map((reserva) => (
                        
                        <tr 
                            key={reserva.id} 
                            className={`${reserva.visto==="0" ? ' font-bold ' : ' bg-blue-100 ' } " hover:cursor-pointer "`}
                            onClick={()=> handleClick(reserva.id)}
                        >
                            <td className='border border-gray-500 p-2'>{reserva.fechacontrato}</td>
                            <td className='border border-gray-500 p-2'>{reserva.apellido}</td>
                            <td className='border border-gray-500 p-2'>{reserva.nombre}</td>
                            <td className='border border-gray-500 p-2'>{reserva.documento}</td>
                            <td className='border border-gray-500 p-2'>{reserva.domicilio}</td>
                            <td className='border border-gray-500 p-2'>{reserva.telefono}</td>
                            <td className='border border-gray-500 p-2'>{reserva.datoscolegio}</td>
                            <td className='border border-gray-500 p-2'>{`${reserva.nombrepaquete} - ${reserva.duracion}`}</td>
                        </tr>
                        ))
                    )
                }
                </tbody>
            </table>
            </div>
            <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-3/4 w-10/12">
                        <div className='bg-white mt-5 p-10 rounded-xl'>
                            <h3 className='text-center text-2xl text-gray-600'>Datos de la reserva</h3>
                            <div className='p-5 shadow-md rounded-lg bg-slate-100 w-full text-gray-600'>
                                <h3 className='text-center text-2xl'>Pasajero</h3>
                                <p>Apellido: {reserva.apellido}</p>
                                <p>Nombre: {reserva.nombre}</p>
                                <p>Documento: {reserva.documento}</p>
                                <p>Fecha de Nacimiento: {reserva.fechanacimiento}</p>
                            </div>
                            <div className='mt-5 shadow-md p-5 rounded-lg bg-slate-100 w-full text-gray-600'>
                                <h3 className='text-center text-2xl'>Tutor</h3>
                                <p>Apellido: {reserva.apellidotutor}</p>
                                <p>Nombre: {reserva.nombretutor}</p>
                                <p>Documento: {reserva.documentotutor}</p>
                            </div>
                            <div className='mt-5 shadow-md p-5 rounded-lg bg-slate-100 w-full text-gray-600'>
                                <h3 className='text-center text-2xl'>Detalles del Paquete</h3>
                                <p>Fecha de contrato: {reserva.fechacontrato}</p>
                                <p>Colegio y Curso: {reserva.datoscolegio}</p>
                                <p>Paquete: {reserva.nombrepaquete}</p>
                                <p>Duración: {reserva.duracion}</p>
                                <p>Forma de Pago: {reserva.tipopago}</p>
                            </div>
                            { reserva.imagen && (
                            <img className='w-full object-cover' src={`${Url}${reserva.imagen}`} />
                            )}
                        </div>
                        <div className='p-3 w-1/3 mx-auto'>
                            <button 
                                className='bg-orange-500 text-white p-3 rounded-xl w-full mx-auto'
                                onClick={()=>setOpen(false)}
                            >Cerrar</button>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
            </Transition.Root>

        </motion.div>

  )
}

export default ListadoReservasNuevas