import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Url } from './Url';


const ImagenesDestino = ({ iddestino }) => {
    const [destino, setDestino] = useState([]);

    const ds = async () => {
        const url = Url + 'api/imagenesdestino.php?iddestino=' + iddestino;
        const resultado = await axios.get(url);
        //console.log(resultado)
        setDestino(resultado.data.results);
    }

    useEffect(() => {
        ds();
    }, [])

    return (
        <>
            {
                destino.map((des, index) => (
                    <div className='w-full h-72 relative' key={index}>
                        <img className="rounded-xl h-72 w-full object-cover" src={Url + des.imagen} alt="img"></img>
                    </div>
                ))
            }
        </>
    );
}

export default ImagenesDestino