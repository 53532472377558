import Layout from '../components/Layout'
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';


const Mpsuccess = () => {
    const areaAnimate={
        offscreen:{y:400, opacity:0},
        onscreen:{
            y:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 2
            }
        }
    }

    return (
        <Layout
            pagina="Nosotros"
        >
            <main className='w-full mt-0 bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat pt-0'>
                <div className='bg-indigo-800/90 w-full h-full pb-96 pt-10 mt-0'>
                    <div className='bg-green-500 w-3/4 mx-auto py-10 rounded-2xl'>
                        <h1 className='text-white text-3xl text-center'>Pago realizado con éxito</h1>
                    </div>
                    <div className='flex w-1/3 mx-auto justify-between'>
                        <Link 
                            className='bg-orange-500 p-3 text-white mt-10 uppercase rounded-lg'
                            to="/cuentas"
                        >
                            Volver a mis cuentas
                        </Link>
                        <Link 
                            className='bg-orange-500 p-3 text-white mt-10 uppercase rounded-lg'
                            to="/"
                        >
                            Volver al inicio
                        </Link>
                    </div>
                </div>
            </main>
        </Layout>
  )
}

export default Mpsuccess