import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css/bundle';
import { Url } from './Url';


const Paquetes = () => {
    const [paquetes, setPaquetes] = useState([]);

    useEffect(() => {
        const Paq = async () => {
            const url = Url + 'api/paquetes.php';
            const resultado = await axios.get(url);
            setPaquetes(resultado.data.results);
        }
        Paq();
    }, [])


    let slides = [];

    paquetes && paquetes.map((paquete, index) => (

        slides.push(
            <SwiperSlide className='h-72' key={index}>
                <a href={paquete.link}>
                    <img className='object-cover w-full h-72 rounded-2xl' src={Url + paquete.imagen} alt="img" />
                    <h1 className='text-white text-3xl text-center mt-10'>{paquete.nombre}</h1>
                    <h3 className='text-orange-500 text-2xl text-center mt-1'>{paquete.duracion}</h3>
                </a>
            </SwiperSlide>
        )
    ));

    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            breakpoints={{
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                // when window width is >= 640px
                800: {
                    slidesPerView: 3,
                    spaceBetween: 40
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 40
                }

            }}
            observer={true}
            centeredSlides={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}

            modules={[Autoplay, Pagination, Navigation]}

            speed={500}
            loop={true}
            touchRatio={1.5}
            effect={"fade"}
            className="mySwiper"
        >
            {slides}
        </Swiper>
    );
}

export default Paquetes