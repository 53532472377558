import { useState, useEffect, useContext } from 'react'
import { motion } from 'framer-motion';
import appContext from '../context/auth/appContext';

const FormDatePicker = () => {
    const [ maxDays, setMaxDays ] = useState(31)
    const [ days, setDays ] = useState([])
    const [ months, setMonths ] = useState([])
    const [ years, setYears ] = useState([])
    const { daySelected, setDaySelected,
        monthSelected, setMonthSelected,
        yearSelected, setYearSelected, setOpenDatePicker,
        setFechaN, fechaN,
        setFechaNT, fechaNT, 
        useDate,setUseDate } = useContext(appContext)

    useEffect(()=>{
        if (useDate===1)
        {
            if (fechaN!=='')
            {
                let vDate = fechaN.split('-');
                setYearSelected(vDate[2]);
                setMonthSelected(vDate[1]);
                setDaySelected(vDate[0]);
            }
    
        }
        else{
            if (fechaNT!=='')
            {
                let vDate = fechaNT.split('-');
                setYearSelected(vDate[2]);
                setMonthSelected(vDate[1]);
                setDaySelected(vDate[0]);
            }

        }
        const getYears = () => {
            let today = new Date();
            let year = today.getFullYear();

            let arrayYears=[];
            for (let i = 1910; i < year; i++) {
                arrayYears.push(i);
            }
            setYears(arrayYears);
        }
        getYears();
        
    },[])

    useEffect(()=>{
        const getMaxDays = () => {
            switch (monthSelected) {
                case '2':
                    console.log(monthSelected)
                    if ((yearSelected%4)===0)
                    {
                        setMaxDays(29)
                    }
                    else
                    {
                        setMaxDays(28)
                    }
                    break;
                case '1':
                case '3':
                case '5':
                case '7':
                case '8':
                case '10':
                case '12':
                    console.log(monthSelected)
                    setMaxDays(31)
                    break;
                case '4':
                case '6':
                case '9':
                case '11':
                    console.log(monthSelected)
                    setMaxDays(30)
                    break;

            }
        }
        getMaxDays()
    },[monthSelected, yearSelected])

    useEffect(() => {
        const getMonths = () => {
            let arrayMonths=[];
            for (let i = 1; i <= 12; i++) {                
                arrayMonths.push(i)
            }
            setMonths(arrayMonths)
        }
        getMonths();
    },[])

    useEffect(()=>{
        console.log(maxDays)
        const getDays = () => {
            let arrayDays=[]
            for (let i = 1; i <= maxDays; i++) {
                arrayDays.push(i)
            }
            setDays(arrayDays)
        }
        getDays()

    },[maxDays])
    const handleSelect = () => {
        if (useDate===1)
        {
            setFechaN(daySelected+'-'+monthSelected+'-'+yearSelected)
        }
        else
        {
            setFechaNT(daySelected+'-'+monthSelected+'-'+yearSelected)
        }
        setOpenDatePicker(false)
    }

    return (
    <div className='w-full'>

        <div className='w-full flex justify-around pt-20'>
            <div className=''>
                <select className='p-2 rounded bg-white text-gray-700' id='sDay' value={daySelected} onChange={(e) => setDaySelected( e.target.value )}>
                    { typeof days !== "undefined" && (
                        days.map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                    )))}
                </select>
            </div>
            <div className=''>
                <select className='p-2 rounded bg-white text-gray-700' id='sMonth' value={monthSelected} onChange={(e) => setMonthSelected( e.target.value )}>
                    { typeof months !== "undefined" && (
                        months.map(item => (
                            <option key={item} value={item}>{`${item===1 ? 'Enero':item===2 ? 'Febrero':item===3 ? 'Marzo':item===4 ? 'Abril':item===5 ? 'Mayo':item===6 ? 'Junio':item===7 ? 'Julio':item===8 ? 'Agosto':item===9 ? 'Septiembre':item===10 ? 'Octubre':item===11 ? 'Noviembre':'Diciembre'}`}</option>
                    )))}
                </select>
            </div>
            <div className=''>
                <select className='p-2 rounded bg-white text-gray-700' id='sYear' value={yearSelected} onChange={(e) => setYearSelected( e.target.value )}>
                    { typeof years !== "undefined" && (
                        years.map(item => (
                            <option key={item} value={item}>{item}</option>
                    )))}
                </select>
            </div>
        </div>
        <div className='w-full mt-10'>
            <div className='w-1/2 mx-auto'>

                <button
                    type='button'
                    className='w-full mt-10 bg-indigo-600 p-3 rounded-xl text-white uppercase text-xl'
                    onClick={()=>handleSelect()}
                >
                    Establecer
                </button>

            </div>
        </div>
    </div>
    )
}

export default FormDatePicker