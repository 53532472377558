import Layout from '../components/Layout'
import Turismos from '../components/Turismos'
import ListadoDestinos from '../components/ListadoDestinos'

const Destinos = () => {
  return (
        <Layout
            pagina="Destinos"
        >
            <main className='w-full h-full bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat'>
                <div className='bg-indigo-800/90 w-full h-full pb-10'>
                    <div className='w-full h-70 bg-black'>
                        <Turismos
                            idservicio={5}
                        />
                    </div>
                    <div className='md:container mt-5 mx-auto p-5 md:p-0 grid lg:grid-cols-4 md:grid-cols-3 md:gap-10 sm:grid-cols-4 sm:gap-2 gap-2'>
                        <ListadoDestinos />                
                    </div>
                </div>
            </main>
        </Layout>
  )
}

export default Destinos