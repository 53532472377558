import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css/bundle';
import { Url } from './Url';

const ListadoPromoDestinos = () => {
    const [ paquetes, setPaquetes ] = useState([]);

    const Paq = async () => {
      const url = Url+'api/promodestinos.php';
      const resultado = await axios.get(url);
      //console.log(resultado)
      setPaquetes(resultado.data.results);
    }

    useEffect(() => {
        Paq();
    }, [])
    

    let slides = [];

    paquetes.slice(0,5).map(paquete => (
        
        slides.push(
            <SwiperSlide className='h-72' key={paquete.id}>
            <a href={paquete.link}>
              <div className='w-full h-72 z-50 rounded-2xl absolute pt-10'>
                <h1 className='text-white text-3xl text-center mt-10'>{paquete.nombredestino}</h1>
                <h3 className='bg-orange-600/90 hover:w-full hover:rounded-none rounded-lg w-32 mx-auto transition-all py-3 text-white text-2xl text-center mt-1'>{paquete.indice}% OFF!</h3>
              </div>
                <div className={'bg-black w-full h-72 z-100 rounded-2xl absolute opacity-50'} >
                </div>
                <img className='object-cover w-full h-72 rounded-2xl' src={Url+paquete.imagen} alt="img" />
            </a>
          </SwiperSlide>
        )
      ));
      return (
        <Swiper
            spaceBetween={50}            
            slidesPerView={1}
            breakpoints= {{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 2,
                  spaceBetween: 30
                },
                // when window width is >= 640px
                800: {
                  slidesPerView: 3,
                  spaceBetween: 40
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 40
                  }
  
            }}
            observer = {true}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            
            modules={[Autoplay, Pagination, Navigation]}
            
            speed={500}
            loop={true}
            touchRatio={1.5}
            effect={"fade"}
            className="mySwiper"
        >
          {slides}
        </Swiper>
      );
}

export default ListadoPromoDestinos