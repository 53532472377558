import { Fragment, useRef, useState, useEffect, useContext } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { motion } from 'framer-motion';
import { Dialog, Transition } from '@headlessui/react'
import { AiOutlineClose } from 'react-icons/ai'
import SubirImagenes from './admin/SubirImagenes';
import appContext from '../context/auth/appContext';
import ModalDate from './ModalDate';
import axios from 'axios';
import { Url } from './Url';
import { Link } from 'react-router-dom';


const NuevaReserva = (props) => {
    const [checked, setChecked] = useState(false);
    const [personalPago, setPersonalPago] = useState(false);
    const [colegios, setColegios] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [paquetes, setPaquetes] = useState([]);
    const [pp, setPP] = useState(0);
    const [finalizado, setFinalizado] = useState(false);
    const [convenio, setConvenio] = useState("");
    const [open, setOpen] = useState(false)
    const [aceptado, setAceptado] = useState(false)
    const [anticipoP, setAnticipoP] = useState(0)
    const [cuotasP, setCuotasP] = useState(0)
    const [restoP, setRestoP] = useState(0)
    const [valorCuotaP, setValorCuotaP] = useState(0)
    const [montos, setMontos] = useState([]);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [idPasajero, setIdPasajero] = useState(0);

    const { colegio, setColegio,
        curso, setCurso,
        paquete, setPaquete, setOpenDatePicker,
        fechaN, fechaNT,
        setUseDate } = useContext(appContext);
    const mail = localStorage.getItem('mail');
    //const cancelButtonRef = useRef(null)

    let tipoImagen = "pasajeros";
    let idHtml = "imagenesPasajero";
    const childRef = useRef()

    const datosPasajero = async () => {

        const formData = new FormData()
        formData.append('documento', formik.values.documento);
        try {
            const response = await axios({
                url: Url + 'api/datospasajero.php',
                method: 'POST',
                data: formData,
            })

            formik.setFieldValue('apellido', response.data.results.apellido);
            formik.setFieldValue('nombre', response.data.results.nombre);
            formik.setFieldValue('fechaN', response.data.results.fechanacimiento);

        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        const datosTutor = async () => {
            const formData = new FormData()
            formData.append('mail', mail);
            try {
                const response = await axios({
                    url: Url + 'api/datostutor.php',
                    method: 'POST',
                    data: formData,
                })
                formik.setFieldValue('documentoT', response.data.results.documento);
                formik.setFieldValue('apellidoT', response.data.results.apellido);
                formik.setFieldValue('nombreT', response.data.results.nombre);
                formik.setFieldValue('fechaNT', response.data.results.fechanacimiento);
                formik.setFieldValue('telefono', response.data.results.telefono);
                formik.setFieldValue('localidad', response.data.results.localidad);
                formik.setFieldValue('calle', response.data.results.calle);
                formik.setFieldValue('numero', response.data.results.numero);
                formik.setFieldValue('piso', response.data.results.piso);
                formik.setFieldValue('depto', response.data.results.depto);
                formik.setFieldValue('observaciones', response.data.results.observaciones);
            } catch (e) {
                //console.log(e)
            }
        }
        datosTutor();
        const Colegios = async () => {
            const url = Url + 'api/listadocolegios.php';
            const resultado = await axios.get(url);
            setColegios(resultado.data.results);
        }
        Colegios();
    }, [])


    useEffect(() => {
        const consultarCursos = async () => {
            if (typeof curso !== "undefined") {
                const formData = new FormData()
                formData.append('colegio', colegio);
                try {
                    const response = await axios({
                        url: Url + 'api/consultarcursos.php',
                        method: 'POST',
                        data: formData,
                    })
                    setCursos(response.data.results)
                } catch (e) {
                    console.log(e)
                }
            }
        }
        consultarCursos();
    }, [colegio])

    useEffect(() => {
        const consultarPaquetes = async () => {
            if (typeof curso !== "undefined") {
                const formData = new FormData()
                formData.append('curso', curso);
                try {
                    const response = await axios({
                        url: Url + 'api/paquetesSelect.php',
                        method: 'POST',
                        data: formData,
                    })
                    setPaquetes(response.data.results)
                } catch (e) {
                    console.log(e)
                }
            }
        }
        consultarPaquetes();
    }, [curso])


    useEffect(() => {
        const consultarMontos = async () => {
            if (typeof paquete !== "undefined") {
                const formData = new FormData()
                formData.append('paquete', paquete);
                try {
                    const response = await axios({
                        url: Url + 'api/consultarmontos.php',
                        method: 'POST',
                        data: formData,
                    })
                    console.log(response.data.results)
                    setMontos(response.data.results)
                } catch (e) {
                    console.log(e)
                }
            }
        }
        consultarMontos();
    }, [paquete])

    useEffect(() => {
        const consultarConvenio = async () => {
            if (open) {
                const formData = new FormData()
                formData.append('mail', mail);
                formData.append('documento', formik.values.documento);
                formData.append('apellido', formik.values.apellido);
                formData.append('nombre', formik.values.nombre);
                formData.append('paquete', paquete);
                formData.append('pp', pp);
                formData.append('curso', curso);
                try {
                    const response = await axios({
                        url: Url + 'api/convenio.php',
                        method: 'POST',
                        data: formData,
                    })
                    setConvenio(response.data)
                } catch (e) {
                    console.log(e)
                }
            }
        }
        consultarConvenio();
    }, [open])

    useEffect(() => {
        if (idPasajero !== 0) {
            if (childRef.current) {
                childRef.current.handleSubmit();
                setIdPasajero(0)
                setFinalizado(true);
            }
            else {
                setFinalizado(true);
            }
        }
    }, [idPasajero])

    useEffect(() => {
        formik.setFieldValue('fechaN', fechaN);
        formik.setFieldValue('fechaNT', fechaNT);
    }, [fechaN, fechaNT])

    const areaAnimate = {
        offscreen: { x: 0, opacity: 0 },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    const handleChange = () => {
        setChecked(!checked);
    };

    const handleDate = (d) => {
        if (d === 1) {
            setUseDate(1)
        }
        else {
            setUseDate(2)
        }
        setOpenDatePicker(true)
    }


    const formik = useFormik({
        initialValues: {
            documento: '',
            apellido: '',
            nombre: '',
            documentoT: '',
            apellidoT: '',
            nombreT: '',
            telefono: '',
            localidad: '',
            calle: '',
            numero: '',
            piso: '',
            depto: '',
            observaciones: ''
        },
        validationSchema: Yup.object({
            documento: Yup.number()
                .typeError("Debe ingresar solo números")
                .required("El Nº de Documento es obligatorio"),
            apellido: Yup.string()
                .required("El Apellido es obligatorio"),
            nombre: Yup.string()
                .required("El Nombre es obligatorio"),
            documentoT: Yup.number()
                .typeError("Debe ingresar solo números")
                .required("El Nº de Documento es obligatorio"),
            apellidoT: Yup.string()
                .required("El Apellido es obligatorio"),
            nombreT: Yup.string()
                .required("El Nombre es obligatorio"),
            telefono: Yup.number()
                .integer('El número no es válido')
                .positive('El número no es válido')
                .typeError('El número no es válido')
                .min(10, 'El número es demasiado corto')
                .required('El teléfono es obligatorio'),
            localidad: Yup.string()
                .required("La localidad es necesaria"),
            calle: Yup.string()
                .required("La calle es necesaria")

        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('mail', mail);
            formData.append('nombre', valores.nombre);
            formData.append('apellido', valores.apellido);
            formData.append('documento', valores.documento);
            formData.append('fechaN', fechaN);
            formData.append('nombreT', valores.nombreT);
            formData.append('apellidoT', valores.apellidoT);
            formData.append('documentoT', valores.documentoT);
            formData.append('fechaNT', fechaNT);
            formData.append('telefono', valores.telefono);
            formData.append('localidad', valores.localidad);
            formData.append('calle', valores.calle);
            formData.append('numero', valores.numero);
            formData.append('piso', valores.piso);
            formData.append('depto', valores.depto);
            formData.append('observaciones', valores.observaciones);
            formData.append('curso', curso);
            formData.append('paquete', paquete);
            formData.append('pp', pp);
            formData.append('anticipo', anticipoP);
            formData.append('cuotas', cuotasP);
            formData.append('valorcuota', valorCuotaP);
            formData.append('personalPago', personalPago);

            if (!personalPago || (personalPago && anticipoP >= min && anticipoP <= max && valorCuotaP !== 0)) {
                try {
                    const response = await axios({
                        url: Url + 'api/nuevareserva.php',
                        method: 'POST',
                        data: formData,
                    })
                    console.log(response)
                    setIdPasajero(response.data.results.idpasajero)
                    resetForm({ values: '' });

                } catch (e) {
                    console.log(e)
                }
            }
        }
    });


    return (
        <motion.form
            onSubmit={formik.handleSubmit}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.1 }}
            variants={areaAnimate}
        >
            {
                !finalizado ? (
                    <div>
                        <div className='bg-orange-500 rounded-2xl pt-3'>
                            <h3 className='text-white text-3xl text-center'>Datos del Pasajero</h3>
                            <div className="transition-all px-4 w-full pt-3 sm:p-6 mt-2">
                                <div className="sm:items-start px-5">
                                    <label htmlFor="documento" className="block text-white font-bold">Nº de Documento</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="documento"
                                        placeholder="Nº de Documento del Pasajero"
                                        value={formik.values.documento}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        onKeyUp={() => datosPasajero()}
                                    />
                                    {formik.touched.documento && formik.errors.documento ? (
                                        <div className='w-full block mb-2 bg-white p-2 rounded-xl text-red-600'>
                                            <p>{formik.errors.documento}</p>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="sm:items-start px-5 mt-2">
                                    <label htmlFor="apellido" className="block text-white font-bold">Apellido</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="apellido"
                                        placeholder="Apellido del Pasajero"
                                        value={formik.values.apellido}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.apellido && formik.errors.apellido ? (
                                        <div className='w-full block mb-2 bg-white p-2 rounded-xl text-red-600'>
                                            <p>{formik.errors.apellido}</p>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="sm:items-start px-5 mt-2">
                                    <label htmlFor="nombre" className="block text-white font-bold">Nombre</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="nombre"
                                        placeholder="Nombre del Pasajero"
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.nombre && formik.errors.nombre ? (
                                        <div className='w-full block mb-2 bg-white p-2 rounded-xl text-red-600'>
                                            <p>{formik.errors.nombre}</p>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="sm:items-start px-5 mt-2">
                                    <label htmlFor="fechaN" className="block text-white font-bold">Fecha de Nacimiento</label>
                                    <input
                                        type='text'
                                        id='fechaN'
                                        value={fechaN}
                                        onClick={() => handleDate(1)}
                                        onChange={() => handleDate(1)}
                                        className="py-2 px-3 rounded"
                                        placeholder='Seleccione fecha'
                                    />

                                    {formik.touched.fechaN && formik.errors.fechaN ? (
                                        <div className='w-full block mb-2 bg-white p-2 rounded-xl text-red-600'>
                                            <p>{formik.errors.fechaN}</p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="px-4 pt-2 pb-5 sm:p-6">
                                <div className="sm:items-start px-5 mt-2">
                                    <p className='block text-white font-bold'>Fotos del DNI (Frente y Dorso)</p>
                                    <div className="p-1">
                                        <SubirImagenes
                                            id={idPasajero}
                                            tipo={tipoImagen}
                                            ref={childRef}
                                            idHtml={idHtml}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-orange-500 rounded-2xl mt-5 pt-3'>
                            <h3 className='text-white text-3xl text-center'>Datos del Tutor</h3>
                            <div className="transition-all px-4 w-full pt-3 sm:p-6 mt-2">
                                <div className="sm:items-start px-5">
                                    <label htmlFor="documentoT" className="block text-white font-bold">Nº de Documento</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="documentoT"
                                        placeholder="Nº de Documento del Tutor"
                                        value={formik.values.documentoT}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.documentoT && formik.errors.documentoT ? (
                                        <div className='w-full block mb-2 bg-white p-2 rounded-xl text-red-600'>
                                            <p>{formik.errors.documentoT}</p>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="sm:items-start px-5 mt-2">
                                    <label htmlFor="apellidoT" className="block text-white font-bold">Apellido</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="apellidoT"
                                        placeholder="Apellido del Tutor"
                                        value={formik.values.apellidoT}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.apellidoT && formik.errors.apellidoT ? (
                                        <div className='w-full block mb-2 bg-white p-2 rounded-xl text-red-600'>
                                            <p>{formik.errors.apellidoT}</p>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="sm:items-start px-5 mt-2">
                                    <label htmlFor="nombreT" className="block text-white font-bold">Nombre</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="nombreT"
                                        placeholder="Nombre del Tutor"
                                        value={formik.values.nombreT}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.nombreT && formik.errors.nombreT ? (
                                        <div className='w-full block mb-2 bg-white p-2 rounded-xl text-red-600'>
                                            <p>{formik.errors.nombreT}</p>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="sm:items-start px-5 mt-2 mb-5">
                                    <label htmlFor="fechaNT" className="block text-white font-bold">Fecha de Nacimiento</label>
                                    <input
                                        type='text'
                                        id='fechaN'
                                        value={fechaNT}
                                        onClick={() => handleDate(2)}
                                        onChange={() => handleDate(2)}
                                        className="py-2 px-3 rounded"
                                        placeholder='Seleccione fecha'
                                    />
                                    {formik.touched.fechaNT && formik.errors.fechaNT ? (
                                        <div className='w-full block mb-2 bg-white p-2 rounded-xl text-red-600'>
                                            <p>{formik.errors.fechaNT}</p>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="sm:items-start px-5 mt-2 mb-5">
                                    <label htmlFor="telefono" className="block text-white uppercase font-bold">Teléfono</label>
                                    <input
                                        type="tel"
                                        className="py-2 px-3 w-full rounded"
                                        id="telefono"
                                        placeholder="Ingrese su número de teléfono"
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.telefono && formik.errors.telefono ? (
                                        <div className='w-full block mb-2 bg-white p-2 rounded-xl text-red-600'>
                                            <p>{formik.errors.telefono}</p>
                                        </div>
                                    ) : null}
                                </div>
                                <hr />
                                <h3 className='mt-5 text-center uppercase text-white text-xl'>Domicilio</h3>
                                <div className="sm:items-start px-5 mt-2">
                                    <label htmlFor="localidad" className="block text-white uppercase font-bold">Localidad</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="localidad"
                                        placeholder="Ingrese la Localidad"
                                        value={formik.values.localidad}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.localidad && formik.errors.localidad ? (
                                        <div className='w-full block mb-2 bg-white p-2 rounded-xl text-red-600'>
                                            <p>{formik.errors.localidad}</p>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="sm:items-start px-5 mt-2">
                                    <label htmlFor="calle" className="block text-white uppercase font-bold">Calle</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="calle"
                                        placeholder="Ingrese el nombre de la calle"
                                        value={formik.values.calle}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.calle && formik.errors.calle ? (
                                        <div className='w-full block mb-2 bg-white p-2 rounded-xl text-red-600'>
                                            <p>{formik.errors.calle}</p>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="sm:items-start px-5 mt-2">
                                    <label htmlFor="numero" className="block text-white uppercase font-bold">Número</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="numero"
                                        placeholder="Numeración de domicilio"
                                        value={formik.values.numero}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                <div className="sm:items-start px-5 mt-2">
                                    <label htmlFor="piso" className="block text-white uppercase font-bold">Piso</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="piso"
                                        placeholder="Ingrese el Número de Piso"
                                        value={formik.values.piso}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                <div className="sm:items-start px-5 mt-2">
                                    <label htmlFor="depto" className="block text-white uppercase font-bold">Departamento</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="depto"
                                        placeholder="Ingrese el Departamento"
                                        value={formik.values.depto}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                <div className="sm:items-start px-5 mt-2 mb-5">
                                    <label htmlFor="observaciones" className="block text-white uppercase font-bold">Ovservaciones del domicilio</label>
                                    <input
                                        type="text"
                                        className="py-2 px-3 w-full rounded"
                                        id="observaciones"
                                        placeholder="Si necesita aclarar algo sobre el domicilio"
                                        value={formik.values.observaciones}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='bg-orange-500 rounded-2xl mt-5 px-5 py-3'>
                            <h3 className='text-white text-3xl text-center'>Datos del Paquete</h3>
                            {
                                props.tipo === 1 && (
                                    <>
                                        <div className='my-5 px-5'>
                                            <label htmlFor="sColegio" className="block text-white font-bold">Colegio</label>
                                            <select className='w-full p-2 rounded bg-white text-gray-700' id='sColegio' onChange={(e) => setColegio(e.target.value)}>
                                                {typeof colegios !== "undefined" && (colegios.map((colegio, index) => (
                                                    <option key={index} value={colegio.id}>{colegio.nombre}</option>
                                                )))}
                                            </select>
                                        </div>
                                        <div className='my-5 px-5'>
                                            <label htmlFor="sPlan" className="block text-white font-bold">Curso</label>
                                            <select className='w-full p-2 rounded bg-white text-gray-700' id='sCurso' onChange={(e) => setCurso(e.target.value)}>
                                                {typeof cursos !== "undefined" && (
                                                    cursos.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.detalle}</option>
                                                    )))}
                                            </select>
                                        </div>

                                        <div className='my-5 px-5'>
                                            <label htmlFor="sPaquete" className="block text-white font-bold">Destino y Tipo de Paquete</label>
                                            <select className='w-full p-2 rounded bg-white text-gray-700' id='sPaquete' onChange={(e) => setPaquete(e.target.value)}>
                                                {typeof paquetes !== "undefined" && (
                                                    paquetes.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.descripcion}</option>
                                                    )))}
                                            </select>
                                        </div>
                                    </>

                                )
                            }
                        </div>
                        <div className='bg-orange-500 rounded-2xl mt-5 px-5 py-3'>
                            <div className='px-5'>
                                <h3 className='text-white text-3xl text-center'>Información de Importes</h3>
                                <div className="w-full">
                                    {typeof montos !== "undefined" && (
                                        montos.map((item, index) => (
                                            <div key={index} className='w-full mt-2'>
                                                {item.moneda === '3' ? (
                                                    <div className='text-white text-lg' key={item.id}>
                                                        <span>{item.descripcion} - {item.moneda1 === '1' ? '$ ' : 'USD '}{item.importe}</span>
                                                        <span>{item.adicional !== '0' && (item.moneda2 === '1' ? ' + $ ' : ' + USD ' + item.adicional)}</span>
                                                        <span> - Seña: {item.moneda1 === '1' ? '$ ' : 'USD '}{item.senia} - Plazo para señar: {item.fechavtosenia}</span>
                                                        <p>{item.infocuotas}</p>
                                                        <p>{item.infovto}</p>
                                                    </div>
                                                ) : (
                                                    <div key={item.id}>
                                                        <p className='text-white text-xl text-center'>{item.descripcion} - {item.moneda === '1' ? '$ ' : 'USD '}{item.importe} - Seña: {item.moneda === '1' ? '$ ' : 'USD '}{item.senia} - Plazo para señar: {item.fechavtosenia}</p>
                                                        <p className='text-white text-xl text-center'>{item.infocuotas}</p>
                                                        <p className='text-white text-sm text-center'>{item.infovto}</p>
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                    )

                                    }
                                    <p className='text-gray-800 text-sm text-center'>(*) La seña debe ser de contado/efectivo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (null)
            }
            {
                !finalizado && (
                    <div className='bg-orange-500 rounded-2xl mt-5 px-5 py-3'>
                        <h3 className='text-white text-3xl text-center'>Condiciones y Convenio</h3>
                        <div className="px-4 pb-5 sm:px-6">
                            {
                                paquete !== 0 ? (
                                    <button
                                        type='button'
                                        className='w-full mt-5 mx-auto shadow-md p-3 bg-gray-300 hover:bg-gray-200 text-bold rounded-lg text-red-500 font-bold px-4 uppercase hover:cursor-pointer'
                                        onClick={() => setOpen(true)}
                                    >
                                        Ver y Aceptar Convenio
                                    </button>
                                ) : (<p className='text-white text-semibold uppercase mt-5 text-center'>Debe completar el formulario para poder ver y aceptar el convenio</p>)
                            }
                        </div>
                    </div>
                )
            }
            <div className='bg-orange-500 rounded-2xl mt-5 px-5 py-3'>

                {
                    !finalizado && aceptado ? (
                        <div className="px-4 pb-5 sm:px-6">
                            <div className="w-full flex">
                                <input type="checkbox" id="aceptar" onChange={handleChange} />
                                <label htmlFor="aceptar" className="ml-5 block text-white font-bold">
                                    Los datos son correctos
                                </label>
                            </div>
                        </div>
                    ) : (null)
                }
                <div className="px-4 pb-5 sm:px-6">
                    {!finalizado ? (
                        <div className="w-full">
                            {checked && paquete !== 0 ? (
                                <input
                                    type="submit"
                                    className="w-full shadow-md p-3 bg-indigo-600 text-gray-100 hover:text-gray-300 hover:cursor-pointer transition-all uppercase font-bold rounded-lg mt-1"
                                    value="Confirmar Reserva"
                                />
                            ) : (
                                <>
                                    <p className='text-white text-center text-lg'>Para poder realizar la reserva es necesario que acepte el Convenio y confirme que los datos son correctos</p>
                                    <input
                                        type="button"
                                        className="w-full shadow-md p-3 bg-gray-500 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-1"
                                        value="Confirmar Reserva"
                                    />
                                </>
                            )
                            }
                        </div>
                    ) : (
                        <div className='bg-orange-500 rounded-2xl pt-3'>
                            <p className='text-white text-center text-xl mb-5'>La reserva fue generada de manera correcta. Los datos de la misma fueron enviados a su correo electrónico. Muchas gracias!</p>
                            <p className='text-white text-center text-xl mb-5'>Recuerde que debe realizar el pago en la Calle 24 de Septiembre 1014, San Miguel de Tucumán, en un plazo de 10 días hábiles a partir de la fecha actual.</p>
                            <div className='w-full'>
                                <Link
                                    className="w-full mx-auto bg-indigo-500 p-3 text-gray-100 hover:text-gray-300 hover:cursor-pointer uppercase font-bold rounded-lg mt-1"
                                    to="/"
                                >
                                    Finalizar
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ModalDate />
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end sm:items-center justify-center p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-full md:w-11/12 pb-10">
                                    <div className="w-full mb-10">
                                        <button
                                            type="button"
                                            className="text-gray-400 hover:text-gray-600 transition-all uppercase font-bold p-5 absolute inset-y-0 right-0 flex"
                                            onClick={() => setOpen(false)}
                                        >
                                            <AiOutlineClose />
                                        </button>
                                    </div>
                                    {typeof convenio !== 'undefined' ? (
                                        <iframe className='w-full h-screen px-10' title='convenio' srcDoc={convenio}></iframe>
                                    ) : (
                                        <p className='text-5xl text-center text-gray-600'>Cargando los datos del convenio...</p>
                                    )}
                                    {finalizado ? (
                                        <button
                                            className='bg-gray-600 text-white uppercase mt-10 p-4 text-center rounded-lg w-full'
                                            onClick={() => (setOpen(false))}
                                        >
                                            Cerrar
                                        </button>
                                    ) : (
                                        <div className='w-11/12 mx-auto'>
                                            <button
                                                className='bg-indigo-600 text-white uppercase mt-10 p-4 text-center rounded-lg w-full'
                                                onClick={() => (setOpen(false), setAceptado(true))}
                                            >
                                                Acepto los términos del convenio
                                            </button>
                                            <button
                                                className='bg-red-600 text-white uppercase mt-10 p-4 text-center rounded-lg w-full'
                                                onClick={() => (setOpen(false), setAceptado(false))}
                                            >
                                                No acepto los términos del convenio
                                            </button>
                                        </div>
                                    )}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </motion.form>
    )
}

export default NuevaReserva