import { motion } from 'framer-motion';
import {FaInstagram,FaFacebook,FaWhatsapp} from 'react-icons/fa'
import {HiOutlineLocationMarker,HiOutlineMail,HiOutlinePhone} from 'react-icons/hi'
import { Url } from './Url';
import { Link } from 'react-router-dom';
import { areaAnimateLR, areaAnimateRL, areaAnimateS } from './Animaciones';


const Footer = () => {

    const today = new Date();
    const year = today.getFullYear();

    return (
        <motion.footer className="bg-orange-600 text-center lg:text-left text-gray-200"
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{once:true, amount: 0.1}}
            variants={areaAnimateS}
        >
            <div className="flex justify-center items-center lg:justify-between p-6 border-b border-gray-300">
                <div className="mr-12 hidden lg:block">
                    <span>Conectate con nosotros en nuestras redes sociales:</span>
                </div>
                <div className="flex justify-center">
                    <a 
                        href="https://www.facebook.com/lideraturismo" 
                        className="mr-6 text-gray-200"
                        target={"_blank"}
                    >
                        <FaFacebook />
                    </a>
                    <a 
                        href="https://www.instagram.com/lideraturismo.tuc/" 
                        className="mr-6 text-gray-200"
                        target={"_blank"}
                    >
                        <FaInstagram />
                    </a>
                    <a 
                        href="https://wa.me/543815532541" 
                        className="mr-6 text-gray-200"
                        target={"_blank"}
                    >
                        <FaWhatsapp />
                    </a>
                </div>
            </div>
            <div className="mx-6 py-10 text-center md:text-left">
                <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <motion.div 
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{once:true, amount: 0.1}}
                        variants={areaAnimateLR}
                    >
                        <Link to="/">
                            <img className='mx-auto' src={Url+'img/logos/logogris.svg'} alt="Lidera Turismo"></img>
                        </Link>
                        <div className='w-full'>
                        <p className="uppercase text-center font-semibold mb-4 mt-20">Horarios de Atención</p>
                        <p className="mb-4 text-center"><span className='font-semibold'>Lunes a Viernes</span> de 9:00 a 13:00 hs. y de 17:00 a 21:00 hs.</p>
                        <p className="mb-4 text-center"><span className='font-semibold'>Sábados</span> de 9:00 a 13:00 hs.</p>
                        </div>
                    </motion.div>
                    <motion.div 
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{once:true, amount: 0.1}}
                        variants={areaAnimateLR}
                    >
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Turismo
                        </h6>
                        <p className="mb-4">
                            <Link to="/estudiantil" className="text-gray-200">Estudiantil</Link>
                        </p>
                        <p className="mb-4">
                            <Link to="/grupal" className="text-gray-200">Grupal</Link>
                        </p>
                        <p className="mb-4">
                            <Link to="/individual" className="text-gray-200">Individual</Link>
                        </p>
                    </motion.div>
                    <motion.div 
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{once:true, amount: 0.1}}
                        variants={areaAnimateRL}
                    >
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Enlaces útiles
                        </h6>
                        <p className="mb-4">
                            <Link to="/destinos" className="text-gray-200">Destinos</Link>
                        </p>
                        <p className="mb-4">
                            <Link to="#!" className="text-gray-200">Turismo</Link>
                        </p>
                        <p className="mb-4">
                            <Link to="/promociones" className="text-gray-200">Promociones</Link>
                        </p>
                        <p>
                            <Link to="/nosotros" className="text-gray-200">Nosotros</Link>
                        </p>
                    </motion.div>
                    <motion.div 
                        className=""
                        initial={"offscreen"}
                        whileInView={"onscreen"}
                        viewport={{once:true, amount: 0.1}}
                        variants={areaAnimateRL}
                    >
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Contacto
                        </h6>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            <HiOutlineLocationMarker className='mr-1' />
                            24 de Septiembre 1014, San Miguel de Tucumán
                        </p>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            <HiOutlineMail className='mr-1' />
                            info@lideraturismo.tur.ar
                        </p>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            <HiOutlinePhone className='mr-1' />
                            + 54 381 497-5571
                        </p>
                        <a
                            className="flex items-center justify-center md:justify-start mb-4"
                            href='https://wa.me/543815532541'
                            target={"_blank"}
                            rel='noreferrer'
                        >
                            <FaWhatsapp className="mr-1" aria-hidden="true" />
                            + 54 381 5532541
                        </a>
                    </motion.div>
                </div>
            </div>
            <motion.div 
                className="text-center p-6 bg-orange-600"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{once:true, amount: 0.1}}
                variants={areaAnimateRL}
            >
                <span>© {year} Copyright:</span>
                <Link className="text-gray-300 font-semibold" to="/">Lidera Turismo</Link>
            </motion.div>
            <motion.div 
                className="text-center p-6 bg-orange-600"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{once:true, amount: 0.1}}
                variants={areaAnimateRL}
            >
                <a className="text-gray-300 font-semibold" href="https://mirx.com.ar" target={"_blank"}><span className='font-light'>Diseño y Desarrollo </span>Mirx Software</a>
            </motion.div>
        </motion.footer>  
    )
}

export default Footer
