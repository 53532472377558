import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import ModalDate from './ModalDate';
import { AiOutlineClose } from 'react-icons/ai'
import axios from 'axios';
import { motion } from 'framer-motion';
import { Url } from './Url';


const ListadoReservas = () => {
    const [reservas, setReservas] = useState([]);
    const mail = localStorage.getItem('mail');
    const [convenio, setConvenio] = useState("");
    const [open, setOpen] = useState(false)


    useEffect(() => {
        const res = async () => {
            const url = `${Url}api/listadoreservas.php?mail=${mail}`;
            const resultado = await axios.get(url);
            //console.log(resultado)
            setReservas(resultado.data.results);
        }
        res();
    }, [])

    const verConvenio = async (item) => {
        const formData = new FormData()
        formData.append('reserva', item.id);
        try {
            const response = await axios({
                url: Url + 'api/getConvenio.php',
                method: 'POST',
                data: formData,
            })
            setConvenio(response.data)
            setOpen(true)
        } catch (e) {
            console.log(e)
        }
    }

    const areaAnimate = {
        offscreen: { x: 0, opacity: 0 },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    return (
        <motion.div className='bg-orange-500 rounded-2xl p-3'
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.1 }}
            variants={areaAnimate}
        >
            <h3 className='text-white text-2xl text-center mb-5'>Mis Reservas</h3>
            <table className='table-auto border-collapse border-spacing-5 border border-slate-400 text-white rounded-xl mx-auto' >
                <thead >
                    <tr>
                        <th className='border border-slate-300 p-2'>Fecha de Reserva</th>
                        <th className='border border-slate-300 p-2'>Apellido</th>
                        <th className='border border-slate-300 p-2'>Nombre</th>
                        <th className='border border-slate-300 p-2'>Colegio / Curso</th>
                        <th className='border border-slate-300 p-2'>Paquete</th>
                        <th className='border border-slate-300 p-2'></th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof reservas !== 'undefined' ? (
                            reservas.map((reserva, index) => (
                                <tr key={index}>
                                    <td className='border border-slate-300 p-2'>{reserva.fechacontrato}</td>
                                    <td className='border border-slate-300 p-2'>{reserva.apellido}</td>
                                    <td className='border border-slate-300 p-2'>{reserva.nombre}</td>
                                    <td className='border border-slate-300 p-2'>{reserva.datoscolegio}</td>
                                    <td className='border border-slate-300 p-2'>{`${reserva.nombrepaquete} - ${reserva.duracion}`}</td>
                                    <td className='border border-slate-300 p-2'><button className='bg-gray-100 text-black text-center p-1 rounded' onClick={() => verConvenio(reserva)}>Ver convenio</button></td>
                                </tr>
                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
            <ModalDate />
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end sm:items-center justify-center p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-full md:w-11/12 pb-10">
                                    <div className="w-full mb-10">
                                        <button
                                            type="button"
                                            className="text-gray-400 hover:text-gray-600 transition-all uppercase font-bold p-5 absolute inset-y-0 right-0 flex"
                                            onClick={() => setOpen(false)}
                                        >
                                            <AiOutlineClose />
                                        </button>
                                    </div>
                                    {typeof convenio !== 'undefined' ? (
                                        <iframe className='w-full h-screen px-10' title='convenio' srcDoc={convenio}></iframe>
                                    ) : (
                                        <p className='text-5xl text-center text-gray-600'>Cargando los datos del convenio...</p>
                                    )}
                                    <button
                                        className='bg-gray-600 text-white uppercase mt-10 p-4 text-center rounded-lg w-full'
                                        onClick={() => (setOpen(false))}
                                    >
                                        Cerrar
                                    </button>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </motion.div>
    )
}

export default ListadoReservas