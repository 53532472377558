const Mensaje = (props) => {
    return (
        <div className='absolute w-full'>
            <div className='bg-orange-500/90 uppercase text-bold text-center text-white p-3 w-11/12 mx-auto rounded-xl z-50'>
                {props.mensaje}
            </div>
        </div>
    );
}
 
export default Mensaje;