import { useState, useEffect, useRef, useContext } from 'react'
import React from 'react'
import Layout from '../../components/admin/Layout'
import axios from 'axios'
import { Url } from '../../components/Url'
import SubirImagenes from '../../components/admin/SubirImagenes'
import toast, { Toaster } from 'react-hot-toast';
import ModalAltaHotel from '../../components/admin/ModalAltaHotel'
import ModalAltaDestino from '../../components/admin/ModalAltaDestino'
import ModalAltaPrograma from '../../components/admin/ModalAltaPrograma'
import appContext from '../../context/auth/appContext'

const AltaPaquetes = () => {
    const [ nombrePaquete, setNombrePaquete ] = useState("");
    const [ descripcion, setDescripcion ] = useState("");
    const [ duracion, setDuracion ] = useState("");
    const [ tiposPaquetes, setTiposPaquetes ] = useState([]);
    const [ tipoPaquete, setTipoPaquete ] = useState("0");

    const [ fechaI, setFechaI ] = useState("");
    const [ fechaF, setFechaF ] = useState("");
    
    const [ subTiposPaquetes, setSubTiposPaquetes ] = useState([]);
    const [ subTipoPaquete, setSubTipoPaquete ] = useState("0");
    
    const [ destinos, setDestinos ] = useState([]);
    const [ destino, setDestino ] = useState("0");

    const [ hoteles, setHoteles ] = useState([]);
    const [ hotel, setHotel ] = useState("0");

    const [ idPaquete, setIdPaquete ] = useState(0);
    const { idDestino,idHotel } = useContext(appContext);

    let tipoImagen = "paquetes";
    let idHtml = "imagenesPaquetes"
    
    const childRef = useRef()

    useEffect(()=>{
        if (idPaquete!==0)
        {
            childRef.current.handleSubmit();
            toast('Paquete agregado exitosamente')
            setNombrePaquete("");
            setDescripcion("");
            setDuracion("");
        }
    },[idPaquete])
    
    useEffect(()=>{
        const consultaTiposPq = async () => {
            const url = Url+'api/tipospaquetes.php?tipocero=0';
            const resultado = await axios.get(url);
            setTiposPaquetes(resultado.data.results);
        }
        consultaTiposPq()
    },[])

    useEffect(()=>{
        const consultaSubTiposPq = async () => {
            const url = Url+'api/subtipospaquetes_alta.php';
            const resultado = await axios.get(url);
            setSubTiposPaquetes(resultado.data.results);
        }
        consultaSubTiposPq()
    },[])

    useEffect(()=>{
        const consultaDestinos = async () => {
            const url = Url+'api/destinos_altas.php';
            const resultado = await axios.get(url);
            setDestinos(resultado.data.results);
        }
        consultaDestinos()
    },[idDestino])

    useEffect(()=>{
        const consultaHoteles = async () => {
            const url = Url+'api/hoteles_altas.php?iddestino='+destino;
            const resultado = await axios.get(url);
            setHoteles(resultado.data.results);
        }
        consultaHoteles()
    },[destino, idHotel])



    const guardarDatos = async () => {
        const formData=new FormData()
        formData.append('nombrePaquete',nombrePaquete);
        formData.append('descripcion',descripcion);
        formData.append('duracion',duracion);
        formData.append('tipoPaquete',tipoPaquete);
        formData.append('subTipoPaquete',subTipoPaquete);
        formData.append('destino',destino);
        formData.append('hotel',hotel);
        formData.append('fechai',fechaI);
        formData.append('fechaf',fechaF);
        try{
            const response = await axios({
                url: Url+'api/guardarpaquete.php',
                method: 'POST',
                data: formData,
            })
            console.log("id devuelto"+response.data.results.idpaquete);

            setIdPaquete(response.data.results.idpaquete);

        } catch (e) {
            console.log(e)
        }
    }
    

    return (
        <Layout
            pagina="Alta de Paquetes"
        >
            <div className='w-full bg-gray-200'>
                <Toaster />
                <div className='flex p-3'>
                    <label htmlFor="nombrePaquete" className="block text-gray-600 font-bold mr-2">Nombre de Paquete</label>
                    <input
                        type="text"
                        id="nombrePaquete"
                        className='rounded p-1'
                        value={nombrePaquete}
                        onChange={(e)=>setNombrePaquete(e.target.value)}
                    />
                </div>
                <div className='flex p-3'>
                    <label htmlFor="descripcion" className="block text-gray-600 font-bold mr-2">Descripción</label>
                    <input
                        type="text"
                        id="descripcion"
                        className='rounded p-1'
                        value={descripcion}
                        onChange={(e)=>setDescripcion(e.target.value)}
                    />
                </div>
                <div className='flex p-3'>
                    <label htmlFor="duracion" className="block text-gray-600 font-bold mr-2">Duración</label>
                    <input
                        type="text"
                        id="duracion"
                        className='rounded p-1'
                        value={duracion}
                        onChange={(e)=>setDuracion(e.target.value)}
                    />
                </div>
                <div className='flex p-5'>
                    <label htmlFor="sTipoPaquete" className="block text-gray-600 font-bold">Tipo de Paquete</label>
                    <select className='bg-white text-gray-600 ml-2 rounded p-1' id='sTipoPaquete' onChange={(e) => setTipoPaquete( e.target.value )}>
                        { typeof tiposPaquetes !== "undefined" ? (
                            tiposPaquetes.map(item => (
                                <option key={item.id} value={item.id}>{item.nombretipo}</option>))
                        ) : null }
                    </select>
                </div>
                <div className='flex p-5'>
                    <label htmlFor="sSubTipoPaquete" className="block text-gray-600 font-bold">Subtipo de Paquete</label>
                    <select className='bg-white text-gray-600 ml-2 rounded p-1' id='sSubTipoPaquete' 
                        onChange={(e) => setSubTipoPaquete( e.target.value )}
                    >
                        { typeof subTiposPaquetes !== "undefined" ? (
                            subTiposPaquetes.map(item => (
                                <option key={item.id} value={item.id}>{item.nombresubtipo}</option>))
                        ) : null }
                    </select>
                </div>
                <div className='flex p-5'>
                    <div className='flex'>
                        <label htmlFor="sDestinos" className="block text-gray-600 font-bold">Destino</label>
                        <select className='bg-white text-gray-600 ml-2 rounded p-1' id='sDestinos' onChange={(e) => setDestino( e.target.value )}>
                            { typeof destinos !== "undefined" ? (
                                destinos.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombredestino}</option>))
                            ) : null }
                        </select>
                    </div>
                    <div className='ml-2'>
                        <ModalAltaDestino />
                    </div>
                </div>
                <div className='flex p-5'>
                    <div className='flex'>
                        <label htmlFor="sHoteles" className="block text-gray-600 font-bold">Hotel</label>
                        <select className='bg-white text-gray-600 ml-2 rounded p-1' id='sHoteles' onChange={(e) => setHotel( e.target.value )}>
                            { typeof hoteles !== "undefined" ? (
                                hoteles.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombrehotel}</option>))
                            ) : null }
                        </select>
                    </div>
                    <div className='ml-2'>
                        <ModalAltaHotel />
                    </div>
                </div>
                <div className='flex p-5'>
                    <div className='flex'>
                        <label htmlFor="" className="block text-gray-600 font-bold">Programas</label>
                    </div>
                    <div className='ml-2'>
                        <ModalAltaPrograma />
                    </div>
                </div>
                <div className='flex p-5'>
                    <label htmlFor="fechai" className="block text-gray-600 font-bold mr-2">Fecha de inicio</label>
                    <input
                        type="date"
                        id="fechai"
                        value={fechaI}
                        className='rounded p-1 text-gray-600'
                        onChange={(e)=>setFechaI(e.target.value)}
                    />
                </div>
                <div className='flex p-5'>
                    <label htmlFor="fechaf" className="block text-gray-600 font-bold mr-2">Fecha de fin</label>
                    <input
                        type="date"
                        id="fechaf"
                        value={fechaF}
                        className='rounded p-1 text-gray-600'
                        onChange={(e)=>setFechaF(e.target.value)}
                    />
                </div>
                <div className='p-5'>
                    <SubirImagenes 
                        id={idPaquete} 
                        tipo={tipoImagen} 
                        ref={childRef}
                        idHtml={idHtml}
                    />
                </div>
                <div className='p-5 mt-5'>
                {
                    nombrePaquete!=="" && descripcion!=="" && duracion!=="" && subTipoPaquete!=="0" && destino!=="0" ? (
                        <button
                            className='bg-indigo-600 hover:bg-indigo-500 text-white uppercase p-3 rounded-lg'
                            onClick={() => { guardarDatos() }}
                        >
                            Guardar Paquete
                        </button>
                    ) : (
                        <button
                            className='bg-gray-500 text-white uppercase p-3 rounded-lg'
                            onClick={() => toast('Por favor complete los datos del paquete') }
                        >
                            Guardar Paquete
                        </button>
                    )
                }
                </div>
            </div>
        </Layout>
    )
}

export default AltaPaquetes