import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../components/Layout';
import PaqueteProgramas from '../components/PaqueteProgramas';
import PaqueteServicios from '../components/PaqueteServicios';
import PaqueteHotel from '../components/PaqueteHotel';
import ImagenesPaquete from '../components/ImagenesPaquete';
import { areaAnimateLR, areaAnimateRL, areaAnimateS } from '../components/Animaciones';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom'
import { Url } from '../components/Url';


const Turismo = () => {
    const [turismo, setTurismo] = useState([]);
    let paquete = useParams()
    

    useEffect(() => {
        const tur = async () => {
            
            if (paquete.id!=='undefined')
            {
                const url = Url + 'api/paquete.php?idpaquete=' + paquete.id;
                const resultado = await axios.get(url);
                //console.log(resultado)
                setTurismo(resultado.data.results);
    
            }
        }
        tur();
    }, [paquete])


    return (
        <Layout
            pagina="Turismo"
        >
            <main className='w-full h-full bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat'>
                <div className='bg-indigo-800/90 w-full h-full pb-10 font-roboto'>
                    <article className='container mx-auto'>
                        <div className='w-full'>
                            <motion.div
                                className='w-full md:w-2/3 mx-auto pt-5 mb-10 relative'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{ once: true, amount: 0.1 }}
                                variants={areaAnimateLR}
                            >
                                <ImagenesPaquete
                                    idpaquete={paquete.id}
                                />
                            </motion.div>
                            <motion.h3 className='text-center mb-5 text-6xl text-orange-400 font-roboto'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{ once: true, amount: 0.1 }}
                                variants={areaAnimateLR}
                            >{turismo.nombre}</motion.h3>

                            <motion.h3 className='text-left italic mt-10 mb-5 text-5xl text-orange-400 font-roboto'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{ once: true, amount: 0.1 }}
                                variants={areaAnimateRL}
                            >Excursiones y divertimentos</motion.h3>
                            <div className='w-full mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4'>
                                <PaqueteProgramas
                                    idpaquete={turismo.id}
                                />
                            </div>

                            <motion.h3 className='text-left italic mt-5 mb-5 text-5xl text-orange-400'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{ once: true, amount: 0.1 }}
                                variants={areaAnimateLR}
                            >Servicios Incluidos</motion.h3>
                            <div className='w-full mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{ once: true, amount: 0.1 }}
                                variants={areaAnimateRL}
                            >
                                <PaqueteServicios
                                    idpaquete={turismo.id}
                                />
                            </div>
                            <motion.h3 className='text-left italic mt-5 mb-5 text-5xl text-orange-400'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{ once: true, amount: 0.1 }}
                                variants={areaAnimateLR}
                            >Hotelería</motion.h3>
                            <div className='w-full mx-auto'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{ once: true, amount: 0.1 }}
                                variants={areaAnimateRL}
                            >
                                <PaqueteHotel
                                    idpaquete={turismo.id}
                                />
                            </div>

                        </div>

                    </article>
                </div>
            </main>
        </Layout>
    )
}

export default Turismo