import { Link } from "react-router-dom";
import Layout from "../../components/admin/Layout";
import PanelConsultas from "../../components/admin/PanelConsultas";
import PanelReservas from "../../components/admin/PanelReservas";
//import Login from "./Login";

const HomeAdmin = () => {
	const tipo = localStorage.getItem('ld_tip');

	return (
		<Layout
			pagina="Inicio"
		>
			<div className="w-full h-screen bg-slate-200 p-10">
				<h1 className="mb-5 text-gray-500 text-3xl text-center">Administración del Sistema</h1>
				{tipo === "1" && (
					<div className="flex">
						<div className="w-1/4 m-5">
							<PanelConsultas />
						</div>
						<div className="w-1/4 m-5">
							<PanelReservas />
						</div>
					</div>
				)}
			</div>
		</Layout>
	)
}

export default HomeAdmin