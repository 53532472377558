import { Fragment, useRef, useState, useContext, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AiOutlineClose } from 'react-icons/ai'
import authContext from '../context/auth/authContext'
import appContext from '../context/auth/appContext'
import FormLogin from './FormLogin'
import FormRegistro from './FormRegistro'

export default function ModalLogin(props) {
    //const [ verLogin, setVerLogin ] = useState(true)
    const { openLogin, setOpenLogin,verLogin, setVerLogin } = useContext(appContext)

    const AuthContext = useContext(authContext);

    const { autenticado } = AuthContext;

    useEffect(() => {
        
        if (autenticado) {
            setOpenLogin(false)
        }
    }, [autenticado])
    
    


    const cancelButtonRef = useRef(null)

    return (
    <>

    <Transition.Root show={openLogin} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenLogin}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <Dialog.Panel className="relative bg-indigo-600 rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full pb-10">
                    <div className="w-full mb-10">
                        <button
                            type="button"
                            className="text-gray-100 hover:text-gray-300 transition-all uppercase font-bold p-5 absolute inset-y-0 right-0 flex"
                            onClick={() => setOpenLogin(false)}
                        >
                            <AiOutlineClose />
                        </button>

                    </div>
                    {
                        verLogin ? (
                            <div>
                                <FormLogin />
                                <div className="w-full rounded-lg px-11 mb-5">
                                    <button
                                        type="button"
                                        className="w-full shadow-md p-3 bg-indigo-500 hover:bg-indigo-400 transition-all text-white uppercase font-bold rounded-lg mt-5"
                                        onClick={() => setVerLogin(false)}
                                    >
                                        Crear Cuenta
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <FormRegistro />
                                <div className="w-full rounded-lg px-11 mb-5">
                                    <button
                                        type="button"
                                        className="w-full shadow-md p-3 bg-indigo-500 hover:bg-indigo-400 transition-all text-white uppercase font-bold rounded-lg mt-5"
                                        onClick={() => setVerLogin(true)}
                                    >
                                        Ir a Iniciar Sesión
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    
    </>
  )
}