import { useState, useEffect, useContext, useRef } from 'react';
import { motion } from 'framer-motion';
import appContext from '../../context/auth/appContext';
import Layout from '../../components/admin/Layout';
import SubirImgNews from '../../components/admin/SubirImgNews';
import axios from 'axios';
import { Url } from '../../components/Url';

const Newsletter = () => {
    const [colegios, setColegios] = useState([])
    
    const { habilitado, colegioSel, setColegioSel } = useContext(appContext);
    let idHtml = "imagenesPrograma";
    const childRef = useRef()

    useEffect(() => {
        const consultarColegios = async () => {
            const formData = new FormData()

            try {
                const response = await axios({
                    url: Url + 'api/listadocolegiosExc.php',
                    method: 'POST',
                    data: formData,
                })
                setColegios(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        consultarColegios();
    }, [])

    const areaAnimate = {
        offscreen: { x: 0, opacity: 0 },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    const handleNews = () => {
        childRef.current.handleSubmit();
    }

    return (
        <Layout
            pagina="Gestión de Consultas"
        >
            <motion.div className='bg-white rounded-2xl p-3 w-full'
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.1 }}
                variants={areaAnimate}
            >
                <h3 className='text-gray-600 text-2xl text-center mb-5'>Newsletter</h3>
                <div className='w-full'>
                    <div className='my-5 py-2 px-5 w-full bg-slate-300 rounded-lg'>
                        <label htmlFor="sColegio" className="block text-white font-bold">Destinatarios (Por colegio)</label>
                        <select className='w-full p-2 rounded bg-white text-gray-700' value={colegioSel} id='sColegio' onChange={(e) => setColegioSel(e.target.value)}>
                            {typeof colegios !== "undefined" && (colegios.map(colegio => (
                                <option key={colegio.id} value={colegio.id}>{colegio.nombre}</option>
                            )))}
                        </select>
                    </div>
                    <div className='px-11 mb-5'>
                        <p className='text-center text-3xl mb-10'>Adjuntar Imagenes</p>
                        <SubirImgNews
                            ref={childRef}
                            idHtml={idHtml}
                        />
                    </div>
                    <div className="bg-white px-4 pb-10 sm:px-6">

                        <div className="bg-white w-full rounded-lg px-5">
                            { habilitado && (
                            <button
                                type="button"
                                className="w-full shadow-sm p-3 bg-blue-500 hover:bg-gray-400 transition-all text-white uppercase font-bold rounded-xl mt-1"
                                onClick={() => handleNews()}
                            >
                                Enviar Emails
                            </button>
                            )}
                        </div>


                    </div>
                </div>
            </motion.div>
        </Layout>
    )
}

export default Newsletter