import { useContext } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { motion } from 'framer-motion';
import authContext from '../../context/auth/authContext';
import Alerta from '../../components/Alerta';
import axios from 'axios';
import Layout from '../../components/admin/Layout';
import { Url } from '../../components/Url';
import toast, { Toaster } from 'react-hot-toast';


const GestionUsuarios = () => {
    const AuthContext = useContext(authContext);

    const { mensaje } = AuthContext;
    const mail = localStorage.getItem('mail');
    const tipo = localStorage.getItem('ld_tip');
    //console.log(token)

    const formik = useFormik({
        initialValues: {
            nombre: '',
            apellido: '',
            mail: '',
            password: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("El Nombre es necesario"),
            apellido: Yup.string()
                .required("El Apellido es necesario"),
            mail: Yup.string()
                .required("El email es necesario")
                .email("El email no es correcto"),
            password: Yup.string()
                .required("La contraseña es necesaria")
                .min(6, "Debe contar con un mínimo de 6 caracteres")
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('mailAdmin', mail);
            formData.append('nombre', valores.nombre);
            formData.append('apellido', valores.apellido);
            formData.append('mail', valores.mail);
            formData.append('password', valores.password);
            try {
                const response = await axios({
                    url: Url + 'api/nuevoUsuarioAdmin.php',
                    method: 'POST',
                    data: formData,
                })
                //if (console.log(response.data.results[0].error===0))
                toast(response.data.results[0].mje)
                if (response.data.results[0].error === 0)
                    resetForm({ values: '' });

            } catch (e) {
                toast(e)
                console.log(e)
            }
        }
    });
    const areaAnimate = {
        offscreen: { x: 0, opacity: 0 },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    return (
        <Layout
            pagina="Gestión Usuarios"
        >
            {tipo === "1" && (
                <div className="w-full h-screen bg-slate-200">
                    <Toaster />
                    <form
                        className="w-10/12 mx-auto mt-10 p-5 bg-white shadow-md rounded-2xl"
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="transition-all w-full pt-5 mt-5">
                            <h2 className='text-center uppercase text-gray-500 text-2xl'>Formulario Nuevo Usuario</h2>
                            <div className="sm:items-start px-5 mt-5">
                                <label htmlFor="apellido" className="block text-gray-500 uppercase font-bold">Apellido</label>
                                <input
                                    type="text"
                                    className="py-2 px-3 w-full rounded"
                                    id="apellido"
                                    placeholder="Ingrese el Apellido"
                                    value={formik.values.apellido}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.apellido && formik.errors.apellido ? (
                                    <div className='w-full block mb-2 text-red-500'>
                                        <p>{formik.errors.apellido}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start px-5 mt-2">
                                <label htmlFor="nombre" className="block text-gray-500 uppercase font-bold">Nombre</label>
                                <input
                                    type="text"
                                    className="py-2 px-3 w-full rounded"
                                    id="nombre"
                                    placeholder="Ingrese el Nombre"
                                    value={formik.values.nombre}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.nombre && formik.errors.nombre ? (
                                    <div className='w-full block mb-2 text-red-500'>
                                        <p>{formik.errors.nombre}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start px-5 mt-2">
                                <label htmlFor="email" className="block text-gray-500 uppercase font-bold">E-mail</label>
                                <input
                                    type="email"
                                    className="py-2 px-3 w-full rounded"
                                    id="mail"
                                    placeholder="Ingrese su E-mail"
                                    value={formik.values.mail}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.mail && formik.errors.mail ? (
                                    <div className='w-full block mb-2 text-red-500'>
                                        <p>{formik.errors.mail}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start px-5 mt-2">
                                <label htmlFor="password" className="block text-gray-500 uppercase font-bold">Contraseña</label>
                                <input
                                    type="password"
                                    className="py-2 px-3 w-full rounded"
                                    id="password"
                                    placeholder="Ingrese una Contraseña"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <div className='w-full block text-red-500'>
                                        <p>{formik.errors.password}</p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="pt-10 pb-10">
                            <div className="w-full rounded-lg px-5">
                                <input
                                    type="submit"
                                    className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 hover:cursor-pointer transition-all uppercase font-bold rounded-lg mt-1"
                                    value="Crear Cuenta"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </Layout>
    )
}

export default GestionUsuarios