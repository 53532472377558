import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import { Url } from '../Url';

const PanelReservas = () => {
    const [ cantReservas, setCantReservas ] = useState({});

    useEffect(() => {
        const consultarReservas = async () => {
            
            try{
                const url = Url+'api/cantidadesreservas.php'
                const response = await axios.get(url);
                //console.log(response.data.results[0].nuevos)
                setCantReservas({
                    "nuevos":response.data.results[0].nuevos,
                    "sinrespuesta":response.data.results[0].sinrespuesta
                })
            } catch (e) {
              console.log(e)
            }
        }
        consultarReservas();
    }, [])

    const areaAnimate={
        offscreen:{x:0, opacity:0},
        onscreen:{
            x:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }


    return (
        <>
            <motion.div className='bg-white rounded-2xl p-3 h-72 shadow hover:shadow-lg'
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{once:true, amount: 0.1}}
                variants={areaAnimate}                    
            >
                <Link
                    to="/admin/gestionreservas"
                >
                    <div className="w-full h-72 pt-5">
                        <h3 className="text-center text-2xl text-gray-600 uppercase">Reservas</h3>
                        <hr/>
                        <div className="mt-10">
                            <p className="text-center text-indigo-600 text-xl">Nuevas reservas: {cantReservas.nuevos}</p>
                        </div>
                    </div>
                </Link>
            </motion.div>
        </>
  )
}

export default PanelReservas