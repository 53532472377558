import Layout from '../components/Layout'
import Turismos from '../components/Turismos'
import ListadoPaquetes from '../components/ListadoPaquetes'
import { FaInstagram, FaFacebook } from 'react-icons/fa'
import { motion } from 'framer-motion';

const Estudiantil = () => {
    const areaAnimate = {
        offscreen: { y: 400, opacity: 0 },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 2
            }
        }
    }

    return (
        <Layout
            pagina="Turismo Estudiantil"
        >
            <main className='w-full h-full bg-black'>
                <div className='bg-[url("http://lideraturismo.tur.ar/lidera/img/logos/logotipo_school.png")] bg-repeat-round'>
                    <div className='bg-black/50 w-full h-full pb-10'>
                        <motion.div className='w-full h-72'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{ once: true, amount: 0.1 }}
                            variants={areaAnimate}
                        >
                            <Turismos
                                idservicio={1}
                            />
                        </motion.div>
                        <div className='container mx-auto mt-10 mb-10 flex justify-center'>
                            <a href="https://www.facebook.com/" className="mr-6 text-orange-600 text-5xl">
                                <FaFacebook />
                            </a>
                            <a href="https://www.instagram.com/lideraturismo.tuc/" className="mr-6 text-orange-600 text-5xl">
                                <FaInstagram />
                            </a>
                        </div>
                        <motion.h2 className='mt-10 text-orange-600 text-5xl text-center uppercase'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{ once: true, amount: 0.1 }}
                            variants={areaAnimate}
                        >
                            Viajes Estudiantiles
                        </motion.h2>
                        <div className='md:container mt-5 mx-auto p-5 md:p-0 grid lg:grid-cols-4 md:grid-cols-3 md:gap-10 sm:grid-cols-4 sm:gap-2 gap-2'>
                            <ListadoPaquetes
                                idtipo={11}
                            />
                        </div>
                        <motion.h2 className='mt-10 text-orange-600 text-5xl text-center uppercase'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{ once: true, amount: 0.1 }}
                            variants={areaAnimate}
                        >
                            Viajes Educativos
                        </motion.h2>
                        <div className='md:container mt-5 mx-auto p-5 md:p-0 grid lg:grid-cols-4 md:grid-cols-3 md:gap-10 sm:grid-cols-4 sm:gap-2 gap-2'>
                            <ListadoPaquetes
                                idtipo={12}
                            />
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default Estudiantil