import { Fragment, useRef, useState, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import appContext from '../context/auth/appContext'
import FormDatePicker from './FormDatePicker'

const ModalDate = () => {
  const { daySelected, setDaySelected,
    monthSelected, setMonthSelected,
    yearSelected, setYearSelected, 
    openDatePicker, setOpenDatePicker} = useContext(appContext)
  
    const cancelButtonRef = useRef(null)

    return (
    <>

        <Transition.Root show={openDatePicker} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenDatePicker}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-orange-600 rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                                <div className="bg-orange-600 px-4 pt-5 sm:p-6">
                                    <div className="sm:flex sm:items-start">
                                        <FormDatePicker/>
                                    </div>
                                </div>
                                <div className="bg-orange-600 px-4 pb-10 sm:px-6">
                                    <div className="bg-orange-600 w-full rounded-lg px-5">
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>
  )
}

export default ModalDate