import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Entrada from "./Entrada";
import { motion } from 'framer-motion';
import { Url } from './Url';


const ListadoPaquetes = ({ idtipo }) => {
    const [Paquetes, setPaquetes] = useState([]);

    //const tipo = idtipo;

    const areaAnimate = {
        offscreen: { y: 400, opacity: 0 },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 2
            }
        }
    }

    useEffect(() => {

        const paq = async () => {
            const url = Url + 'api/paquetes.php?idtipo=' + idtipo;
            const resultado = await axios.get(url);
            console.log(resultado)
            setPaquetes(resultado.data.results);
        }
        paq();
    }, [])

    return (
        <>
            {
                Paquetes && Paquetes.map((tipo, index) => (
                    <a href={`/turismo/${tipo.id}`} key={index}>
                        <motion.div className="bg-black/30 rounded-xl drop-shadow-md hover:drop-shadow-xl"
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{ once: true, amount: 0.1 }}
                            variants={areaAnimate}
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 1 },
                            }}
                        >
                            <div className='relative'>
                                <Entrada
                                    slide={tipo}
                                />
                            </div>
                            <div className='pt-3 pb-3'>
                                <h1 className='text-white text-2xl text-center '>{tipo.nombre}</h1>
                                <h1 className='text-orange-500 text-2xl text-center '>{tipo.duracion}</h1>
                            </div>
                        </motion.div>
                    </a>
                ))
            }
        </>
    );
}

export default ListadoPaquetes