import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { CSVLink } from "react-csv";
import { Url } from '../Url';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'
import { BsDownload } from "react-icons/bs"
import appContext from '../../context/auth/appContext';

const ListadoClientes = () => {
    const [clientes, setClientes] = useState([]);
    const [colegios, setColegios] = useState([])
    const [colegio, setColegio] = useState(0)
    const { setUsuarioSelected, setOpenVista, setTipoModal, cliMod } = useContext(appContext)
    const tipo = localStorage.getItem('ld_tip');

    useEffect(() => {
        const consultarClientes = async () => {
            const formData = new FormData()
            formData.append('colegio', colegio);
            try {
                const response = await axios({
                    url: Url + 'api/listadoclientes.php',
                    method: 'POST',
                    data: formData,
                })
                setClientes(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        consultarClientes();
    }, [colegio, cliMod])

    useEffect(() => {
        const consultarColegios = async () => {
            const formData = new FormData()

            try {
                const response = await axios({
                    url: Url + 'api/listadocolegiosExc.php',
                    method: 'POST',
                    data: formData,
                })
                setColegios(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        consultarColegios();
    }, [])

    const editarUsuario = (item) => {
        setUsuarioSelected(item)
        setTipoModal(1);
        setOpenVista(true);
    }
    const eliminarUsuario = (item) => {
        setUsuarioSelected(item)
        setTipoModal(2);
        setOpenVista(true);
    }

    return (
        <>
            <div className='bg-white rounded-2xl p-3'>
                <h3 className='text-gray-600 text-2xl text-center mb-5'>Consultas</h3>
                <div className='my-5 py-2 px-5 w-full bg-slate-300 rounded-lg'>
                    <label htmlFor="sColegio" className="block text-white font-bold">Colegio</label>
                    <select className='w-full p-2 rounded bg-white text-gray-700' id='sColegio' onChange={(e) => setColegio(e.target.value)}>
                        {typeof colegios !== "undefined" && (colegios.map(colegio => (
                            <option key={colegio.id} value={colegio.id}>{colegio.nombre}</option>
                        )))}
                    </select>
                </div>
                {typeof clientes !== 'undefined' && (
                    <CSVLink data={clientes}>
                        <button type='button' className='bg-green-600 text-white p-3 rounded-md flex hover:bg-green-500'>
                            <p className='text-xl mr-2'>
                                <BsDownload className='mr-2' />
                            </p>
                            Exportar a CSV
                        </button>
                    </CSVLink>
                )}
                <table className='table-auto border-collapse border-spacing-5 border border-gray-300 text-gray-600 rounded-xl mx-auto' >
                    <thead >
                        <tr className='border border-gray-300 p-2 w-full'>
                            <th className='p-2 w-1/5'>Apellido y Nombre</th>
                            <th className='p-2 w-1/5'>E-mail</th>
                            <th className='p-2 w-1/5'>Teléfono</th>
                            <th className='p-2 w-1/5'>Colegio</th>
                            <th className='p-2 w-1/5'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            typeof clientes !== 'undefined' && (
                                clientes.map((item, index) => (
                                    <tr
                                        className='border border-gray-300 hover:cursor-pointer'
                                        key={index}
                                    >
                                        <td className='p-2'>{`${item.apellido}, ${item.nombre}`}</td>
                                        <td className='p-2'>{`${item.mail}`}</td>
                                        <td className='p-2'>{`${item.telefono}`}</td>
                                        <td className='p-2'>{item.colegio ? item.colegio : null}</td>
                                        {tipo === "1" ? (
                                            <td className='p-2'>
                                                <button
                                                    className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                                    onClick={() => editarUsuario(item)}
                                                >
                                                    <FaPen />
                                                </button>
                                                <button
                                                    className='bg-red-400 p-3 rounded-md text-white ml-3'
                                                    onClick={() => eliminarUsuario(item)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        ) : (<td></td>)}
                                    </tr>
                                ))
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ListadoClientes