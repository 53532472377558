import { useContext } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { motion } from 'framer-motion';
import authContext from '../context/auth/authContext'
import Alerta from './Alerta'
import { Link } from 'react-router-dom';

export default function FormLogin(props) {

    const AuthContext = useContext(authContext);

    const { mensaje, iniciarSesion } = AuthContext;


    const formik = useFormik({
        initialValues:{
            mail: '',
            password: ''
        },
        validationSchema: Yup.object({
            mail: Yup.string()
                    .required("El email es obligatorio")
                    .email("El email no es correcto"),
            password: Yup.string()
                    .required("Debe ingresar un Password")
                    .min(6,"Debe contar con un mínimo de 6 caracteres")
        }),
        onSubmit: valores => {
            iniciarSesion(valores)
        }
    });
    const areaAnimate={
        offscreen:{x:0, opacity:0},
        onscreen:{
            x:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    //const cancelButtonRef = useRef(null)

    return (
        <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{once: false, amount: 0.1}}
            variants={areaAnimate}
        >
            { mensaje ? <Alerta /> : null }
            <form
                className="w-full mx-auto mt-10 p-5 bg-indigo-600 rounded-2xl"
                onSubmit={formik.handleSubmit}
            >
                <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                    <div className="sm:items-start">
                        <label htmlFor="mail" className="block mt-5 text-white uppercase font-bold">E-mail</label>
                        <input 
                            className="py-2 px-3 w-full rounded" 
                            id="mail" 
                            placeholder="Ingrese su E-mail" 
                            type="text" 
                            value={formik.values.mail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus
                        />
                        { formik.touched.mail && formik.errors.mail ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.mail}</p>
                            </div>
                        ) : null }
                    </div>
                    <div className="sm:items-start mt-5">
                        <label htmlFor="password" className="block text-white uppercase font-bold">Contraseña</label>
                        <input 
                            className="py-2 px-3 w-full rounded" 
                            id="password" 
                            placeholder="Ingrese su Contraseña" 
                            type="password" 
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        { formik.touched.password && formik.errors.password ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.password}</p>
                            </div>
                        ) : null }
                    </div>
                </div>
                <div className="bg-indigo-600 pb-10">
                    <div className="bg-indigo-600 w-full rounded-lg px-3">
                        <Link
                            to='/recuperarcuenta'
                            className='text-blue-300'
                        >¿Has olvidado tu contraseña?</Link>
                        <input
                            type="submit"
                            className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                            value="Iniciar Sesión"
                        />
                    </div>
                </div>
            </form>                        
        </motion.div>
  )
}