import React from 'react'
import Layout from '../../components/admin/Layout'
import ListadoConsultas from '../../components/admin/ListadoConsultas'


const GestionConsultas = () => {
	const tipo = localStorage.getItem('ld_tip');

	return (
		<Layout
			pagina="Gestión de Consultas"
		>
			{tipo === "1" && (
				<div
					className='w-full h-screen bg-slate-200'
				>
					<h1 className='mt-10 text-gray-500 text-center mb-5 text-3xl'>Gestión de Consultas</h1>
					<ListadoConsultas />
				</div>
			)}
		</Layout>
	)
}

export default GestionConsultas