import { useContext } from 'react'
import authContext from '../../context/auth/authContext'
import Layout from '../../components/admin/Layout'
import { Link } from 'react-router-dom'

const Configuracion = () => {
    const AuthContext = useContext(authContext);
    const { mensaje } = AuthContext;
    const mail = localStorage.getItem('mail');
    const tipo = localStorage.getItem('ld_tip');

    const areaAnimate = {
        offscreen: { x: 0, opacity: 0 },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }


    return (
        <Layout
            pagina="Configuración"
        >
            <div className="w-full h-screen bg-slate-200">
                <h1 className="mt-10 mb-5 text-gray-500 text-3xl text-center">Configuración</h1>
                <div className="flex">
                    <Link
                        to={"/admin/setpassword"}
                    >
                        <p className='p-5 mx-5 rounded-2xl shadow hover:shadow-xl hover:cursor-pointer bg-white text-gray-500'>Cambiar Contraseña</p>
                    </Link>
                    {tipo === "1" && (
                        <Link
                            to={"/admin/gestionusuarios"}
                        >
                            <p className='p-5 mx-5 rounded-2xl shadow hover:shadow-xl hover:cursor-pointer bg-white text-gray-500'>Gestionar Usuarios</p>
                        </Link>
                    )}

                </div>
            </div>
        </Layout>
    )
}

export default Configuracion