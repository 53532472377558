import Servicios from './Servicios';

const Turismos = ({idservicio,lnk}) => {
    return (
        <div>
            <Servicios 
                idservicio={idservicio}
                lnk={lnk}
            />
        </div>
    );
}

export default Turismos