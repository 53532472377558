import react, { useContext } from 'react'
import authContext from '../../context/auth/authContext'
import Alerta from '../../components/Alerta'
import { useFormik } from "formik"
import * as Yup from "yup"
import Layout from '../../components/admin/Layout'
import axios from 'axios'
import { Url } from '../../components/Url'
import toast, { Toaster } from 'react-hot-toast';

const SetPassword = () => {
    const AuthContext = useContext(authContext);

    const { mensaje } = AuthContext;

    const mail = localStorage.getItem('mail');


    const formik = useFormik({
        initialValues:{
            passwordPrev: '',
            passwordNew: '',
            password2: ''
        },
        validationSchema: Yup.object({
            passwordPrev: Yup.string()
                    .required("Debe ingresar un Password")
                    .min(6,"Debe contar con un mínimo de 6 caracteres"),
            passwordNew: Yup.string()
                    .required("Debe ingresar un Password")
                    .min(6,"Debe contar con un mínimo de 6 caracteres"),
            password2: Yup.string()
                    .required("Reingrese la Contraseña")
                    .oneOf([Yup.ref('passwordNew')], 'Las contraseñas no coinciden.')        }),
            onSubmit: async (valores,{resetForm}) => {
                const formData=new FormData()
                formData.append('mail',mail);
                formData.append('passwordPrev',valores.passwordPrev);
                formData.append('password',valores.passwordNew);
                try{
                    const response = await axios({
                        url: Url+'api/cambiarpassword.php',
                        method: 'POST',
                        data: formData,
                    })
                    if (response.data===0)
                    {
                        toast('La contraseña fue modificada con éxito!')
                        resetForm({ values: ''});
                    }
                    else {
                        toast('La contraseña actual no es correcta!')
                    }
        
                    //setIdPaquete(response.data.results.idpaquete);
        
                } catch (e) {
                    console.log(e)
                }
        

                
            }
    });
    const areaAnimate={
        offscreen:{x:0, opacity:0},
        onscreen:{
            x:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }


    return (
        <Layout
            pagina="Configuración"
        >
            <div
                className='md:w-1/3 w-8/12 mx-auto mt-10 mb-10 p-5 bg-gray-400 rounded-2xl'
            >
                { mensaje ? <Alerta /> : null }
                <form
                    
                    onSubmit={formik.handleSubmit}
                >
                    <Toaster />
                    <h1 className='text-white text-2xl'>Modificar contraseña</h1>
                    <div className="transition-all w-full pt-5 mt-5">
                        <div className="sm:items-start mt-5">
                            <label htmlFor="passwordPrev" className="block text-white uppercase font-bold">Contraseña Anterior</label>
                            <input 
                                className="py-3 w-full rounded" 
                                id="passwordPrev" 
                                placeholder="Ingrese su Contraseña actual" 
                                type="password" 
                                value={formik.values.passwordPrev}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.passwordPrev && formik.errors.passwordPrev ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.passwordPrev}</p>
                                </div>
                            ) : null }
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="passwordNew" className="block text-white uppercase font-bold">Nueva Contraseña</label>
                            <input 
                                className="py-3 w-full rounded" 
                                id="passwordNew" 
                                placeholder="Ingrese una Contraseña nueva" 
                                type="password" 
                                value={formik.values.passwordNew}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.passwordNew && formik.errors.passwordNew ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.passwordNew}</p>
                                </div>
                            ) : null }
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="password2" className="block text-white uppercase font-bold">Confirmar Contraseña</label>
                            <input 
                                className="py-3 w-full rounded" 
                                id="password2" 
                                placeholder="Confirme su nueva Contraseña" 
                                type="password" 
                                value={formik.values.password2}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.password2 && formik.errors.password2 ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.password2}</p>
                                </div>
                            ) : null }
                        </div>
                    </div>
                    <div className="pt-10">
                        <div className="w-full rounded-lg">
                            <input
                                type="submit"
                                className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                                value="Modificar Contraseña"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
  )
}

export default SetPassword