import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../components/Layout';
import PaqueteProgramas from '../components/PaqueteProgramas';
import PaqueteServicios from '../components/PaqueteServicios';
import PaqueteHotel from '../components/PaqueteHotel';
import ImagenesDestino from '../components/ImagenesDestino';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom'
import { Url } from '../components/Url';


const Destino = () => {
    const [ destinos, setDestinos] = useState([]);
    const areaAnimate={
        offscreen:{x:900, opacity:0},
        onscreen:{
            x:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 2
            }
        }
    }
    const des = async () => {
        const url = Url+'api/destino.php?iddestino='+destino.id;
        const resultado = await axios.get(url);
        //console.log(resultado)
        setDestinos(resultado.data.results);
    }

    useEffect(() => {
        des();
    }, [])
    
    let destino=useParams()

    return (
        <Layout
            pagina="Destino"
        >
            <main className='w-full h-full bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat'>
                <div className='bg-indigo-800/90 w-full h-full pb-10'>
                    <article className='container mx-auto'>
                        {
                            destinos.map( result => (
                                <div className='w-full' key={result.id}
                                >
                                    <motion.h1 className='text-center pt-5 mb-5 text-6xl text-orange-500'
                                        initial={"offscreen"}
                                        whileInView={"onscreen"}
                                        viewport={{once:true, amount: 0.1}}
                                        variants={areaAnimate}
                                    >{result.nombredestino}</motion.h1>
                                    <div className='md:container mt-5 mx-auto p-5 md:p-0 grid lg:grid-cols-4 md:grid-cols-3 md:gap-10 sm:grid-cols-4 sm:gap-2 gap-2'>
                                        <ImagenesDestino
                                            iddestino={ result.id }
                                        />
                                    </div>
                                    <motion.h1 className='text-left italic mt-5 mb-5 text-5xl text-orange-500'
                                        initial={"offscreen"}
                                        whileInView={"onscreen"}
                                        viewport={{once:true, amount: 0.1}}
                                        variants={areaAnimate}
                                    >Servicios Incluidos</motion.h1>
                                    <div className='w-full mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4'
                                        initial={"offscreen"}
                                        whileInView={"onscreen"}
                                        viewport={{once:true, amount: 0.1}}
                                        variants={areaAnimate}
                                    >
                                        <PaqueteServicios />
                                    </div>
                                </div>
                            ))
                        }
                    </article>
                </div>
            </main>
        </Layout>
    )
}

export default Destino