import Layout from '../components/Layout'

import { motion } from 'framer-motion';


const Nosotros = () => {
    const areaAnimate={
        offscreen:{y:400, opacity:0},
        onscreen:{
            y:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 2
            }
        }
    }

    return (
        <Layout
            pagina="Nosotros"
        >
            <main className='w-full mt-0 bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat pt-0'>
                <div className='bg-indigo-800/90 w-full h-full pb-10 pt-2 mt-0'>
                    <div className='container mx-auto w-full h-70'>
                        <motion.h1 className='text-center text-orange-600 text-5xl mt-10 font-semibold uppercase'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{once:true, amount: 0.1}}
                            variants={areaAnimate}
                        >Sobre Nosotros</motion.h1>
                        <motion.div className='container mx-auto'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{once:true, amount: 0.1}}
                            variants={areaAnimate}
                        >
                            <div className='container mx-auto mt-5 bg-black/10 rounded-2xl p-10'>
                                <p className='text-orange-500 text-4xl'>Lidera Turismo es la Empresa líder del turismo en Tucumán. Con mas de 12 años de reconocida trayectoria en el Mercado Turístico Nacional e Internacional.</p>
                            </div>
                        </motion.div>
                        <div className='md:container mt-5 mx-auto p-5 md:p-0 grid lg:grid-cols-2 md:grid-cols-2 md:gap-10 sm:grid-cols-1 sm:gap-2 gap-2'>
                            <motion.div className='bg-indigo-800/70 p-10 rounded-2xl'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{once:true, amount: 0.1}}
                                variants={areaAnimate}
                            >
                                <h2 className='text-orange-600 text-5xl text-center font-semibold uppercase'>Misión</h2>
                                <h3 className='text-orange-500 text-3xl mt-4'>En LDT trabajamos en ser los mejores para que nuestros clientes confíen en nosotros; diferenciándonos de los demás por la calidad de nuestro trabajo, tanto en turismo como en la organización de eventos y por nuestro grupo humano, que constituye un excelente equipo con valores bien definidos y es nuestra principal fortaleza.</h3>
                            </motion.div>
                            <motion.div className='bg-indigo-800/70 p-10 rounded-2xl'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{once:true, amount: 0.1}}
                                variants={areaAnimate}
                            >
                                <h2 className='text-orange-600 text-5xl text-center font-semibold uppercase'>Visión</h2>
                                <h3 className='text-orange-500 text-3xl mt-4'>En el grupo LDT apuntamos a consolidarnos como organización creciendo continuamente con el equipo estable de personas óptimamente capacitadas y con valores bien definidos; ofreciendo servicios en relación al turismo y a la organización de eventos siempre marcando la diferencia y dispuestas a nuevos desafíos; teniendo como foco principal la satisfacción de nuestros clientes y el fortalecimiento de nuestro grupo humano.</h3>
                            </motion.div>
                        </div>
                            <motion.h3 className='mt-10 text-5xl text-center text-orange-600 font-semibold uppercase'
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{once:true, amount: 0.1}}
                                variants={areaAnimate}
                            >
                                Nuestros Valores
                            </motion.h3>
                            <div className='md:container mt-5 mx-auto p-5 md:p-0 grid lg:grid-cols-2 md:grid-cols-2 md:gap-10 sm:grid-cols-1 sm:gap-2 gap-2'>
                                <motion.div className='bg-indigo-800/70 p-10 rounded-2xl'
                                    initial={"offscreen"}
                                    whileInView={"onscreen"}
                                    viewport={{once:true, amount: 0.1}}
                                    variants={areaAnimate}
                                >
                                    <h3 className='text-orange-500 text-5xl text-center font-semibold mt-4 uppercase'>Amor</h3>
                                    <h4 className='text-orange-500 text-3xl mt-3'>Entendido como el sentimiento de afecto, inclinación o entrega a alguien o algo.</h4>
                                </motion.div>
                                <motion.div className='bg-indigo-800/70 p-10 rounded-2xl'
                                    initial={"offscreen"}
                                    whileInView={"onscreen"}
                                    viewport={{once:true, amount: 0.1}}
                                    variants={areaAnimate}
                                >
                                    <h3 className='text-orange-500 text-5xl text-center font-semibold mt-4 uppercase'>Honestidad</h3>
                                    <h4 className='text-orange-500 text-3xl mt-3'>Cualidad de calidad humana que consiste en comportarse y expresarse con coherencia y sinceridad y de acuerdo con los valores de verdad y justicia.</h4>
                                </motion.div>
                                <motion.div className='bg-indigo-800/70 p-10 rounded-2xl'
                                    initial={"offscreen"}
                                    whileInView={"onscreen"}
                                    viewport={{once:true, amount: 0.1}}
                                    variants={areaAnimate}
                                >
                                    <h3 className='text-orange-500 text-5xl text-center font-semibold mt-4 uppercase'>Compromiso</h3>
                                    <h4 className='text-orange-500 text-3xl mt-3'>Una persona comprometida es aquella que cumple con sus obligaciones haciendo un poco mas de lo esperado al grado de sorprendernos, porque vive, sueña con sacar adelante a su familia, su trabajo, su estudio y todo aquello en lo que se ha empeñado con su palabra.</h4>
                                </motion.div>
                                <motion.div className='bg-indigo-800/70 p-10 rounded-2xl'
                                    initial={"offscreen"}
                                    whileInView={"onscreen"}
                                    viewport={{once:true, amount: 0.1}}
                                    variants={areaAnimate}
                                >
                                    <h3 className='text-orange-500 text-5xl text-center font-semibold mt-4 uppercase'>Lealtad</h3>
                                    <h4 className='text-orange-500 text-3xl mt-3'>Compromiso en el cumplimiento de las leyes de fidelidad, honor y hombría de bien.</h4>
                                </motion.div>
                                <motion.div className='bg-indigo-800/70 p-10 rounded-2xl'
                                    initial={"offscreen"}
                                    whileInView={"onscreen"}
                                    viewport={{once:true, amount: 0.1}}
                                    variants={areaAnimate}
                                >
                                    <h3 className='text-orange-500 text-5xl text-center font-semibold mt-4 uppercase'>Actitud</h3>
                                    <h4 className='text-orange-500 text-3xl mt-3'>Es nuestra respuesta emocional a las circunstancias de la vida.</h4>
                                </motion.div>
                            </div>
                    </div>
                </div>
            </main>
        </Layout>
  )
}

export default Nosotros