import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Url } from './Url';


const ImagenesPaquete = ({idpaquete}) => {
    const [ paquete, setPaquete] = useState([]);

    

    useEffect(() => {
        const pq = async () => {
            const url = Url+'api/imagenespaquete.php?idpaquete='+idpaquete;
            const resultado = await axios.get(url);
            console.log("asdfasdf  ",resultado)
            setPaquete(resultado.data.results);
        }
        pq()
    }, [])
    
    return (
        <>
            { typeof paquete!=='undefined' &&
                paquete.map((paq, index) => (
                    <div className='w-full relative' key={index}>
                        <img className="rounded w-full object-cover" src={Url+paq.imagen} alt="img"></img>
                    </div>
                ))      
            }
        </>
    );
}

export default ImagenesPaquete