import { useContext } from "react";
import appContext from "../../context/auth/appContext";
import axios from "axios";
import { Url } from "../Url";


const FDelUsuario = () => {
    const { usuarioSelected, setOpenVista, setCliMod, cliMod } = useContext(appContext)

    const handleDelete = async () => {
        const formData = new FormData()
        formData.append('id', usuarioSelected.id);
        try {
            const response = await axios({
                url: Url + 'api/delcliente.php',
                method: 'POST',
                data: formData,
            })
            let aux = cliMod + 1;
            setCliMod(aux)
            setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }



    return (
        <div className="px-4 pt-5 sm:p-6">
            <h2 className="text-3xl text-center text-gray-600 font-bold uppercase">Eliminar Cliente</h2>
            <div className="transition-all px-4 w-full pt-5 sm:p-6">
                <div className="sm:items-start mt-2">
                    <p className="text-center text-xl text-gray-600">Apellido: {usuarioSelected.apellido}</p>
                </div>
                <div className="sm:items-start mt-2">
                    <p className="text-center text-xl text-gray-600">Nombre: {usuarioSelected.nombre}</p>
                </div>
                <div className="sm:items-start mt-2">
                    <p className="text-center text-xl text-gray-600">Teléfono: {usuarioSelected.telefono}</p>
                </div>
                <div className="sm:items-start mt-2">
                    <p className="text-center text-xl text-gray-600">E-Mail: {usuarioSelected.mail}</p>
                </div>
            </div>
            <div className="px-4 pb-10 sm:px-6">
                <div className="w-full rounded-lg">
                    <button
                        type="button"
                        className="w-full shadow-md p-3 bg-red-600 text-gray-100 hover:text-gray-300 hover:cursor-pointer transition-all uppercase font-bold rounded-lg mt-1"
                        onClick={()=>handleDelete()}
                    >Eliminar</button>
                </div>
            </div>
            <div className="px-4 pb-10 sm:px-6">
                <div className="w-full rounded-lg px-5">
                    <button
                        type="button"
                        className="w-full shadow-sm p-3 bg-gray-600 hover:bg-gray-500 transition-all text-white uppercase font-bold rounded-xl mt-1"
                        onClick={() => setOpenVista(false)}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FDelUsuario