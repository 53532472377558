import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "./Header"
import Footer from "./Footer"
import authContext from '../context/auth/authContext';


const Layout = ({children, pagina}) => {
  const AuthContext = useContext( authContext);
  const { usuarioAutenticado } = AuthContext;

  useEffect(() => {
    usuarioAutenticado()
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <title>Lidera Turismo {pagina}</title>
        <meta name="description" content="Empresa de Turismo Lider en Tucumán" />
        <meta property="og:title" content="Lidera Turismo | Agencia de Viajes" />        
        <meta property="og:description" content="Empresa de Turismo Lider en Tucumán" />        
        <meta property="og:image" content="https://lideraturismo.tur.ar/lidera/img/logos/logo.png" />
        <meta property="og:url" content="https://lideraturismo.tur.ar" />
        <meta property="og:site_name" content="Lidera Turismo | Agencia de Viajes" />
        <meta property="og:locale" content="es_AR" />
        <meta property="og:type" content="article" />
      </Helmet>
      <div>
          <Header />
          {children}
          <Footer />
      </div>
    </HelmetProvider>
  )
}

export default Layout