import React, { useContext, useEffect } from 'react';
import { Transition,Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { FaInstagram,FaFacebook,FaWhatsapp } from 'react-icons/fa'
import { Url } from './Url';
import ModalLogin from './ModalLogin';
import authContext from '../context/auth/authContext';
import appContext from '../context/auth/appContext';
import { Link } from 'react-router-dom';
import logo from '../assets/logonuevo.png'


const navigation = [
  
  { name: 'Estudiantil', href: '/estudiantil', current: false },
  { name: 'Nacional', href: '/nacional', current: false },
  { name: 'Internacional', href: '/internacional', current: false },
  { name: 'Receptivo', href: '/receptivo', current: false },
  { name: 'Destinos', href: '/destinos', current: false },
  

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const NavBar = () => {
    const AuthContext = useContext( authContext);
    const { usuario, usuarioAutenticado, cerrarSesion } = AuthContext;
    const mail = localStorage.getItem('mail');
    const {openConsulta, setOpenConsulta, setOpenLogin, setVerLogin} = useContext(appContext)
  
    useEffect(() => {
      usuarioAutenticado()
    }, []);

   

    return (
        <Disclosure as="nav" className="shadow-xl  bg-gradient-to-r from-indigo-400 to-indigo-700">
        {({ open }) => (
            <>
            <div className="container mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-24">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        {/* Mobile menu button*/}
                        <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-100 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                            <span className="sr-only">Abrir Menú</span>
                            {open ? (
                                <XIcon className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                            )}
                        </Disclosure.Button>
                    </div>
                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex-shrink-0 flex items-center">
                            <Link to="/">
                                <img
                                    className="block lg:hidden h-12 w-auto"
                                    src={logo}
                                    alt="Lidera Turismo"
                                />
                                <img
                                    className="hidden lg:block h-12 w-auto"
                                    src={logo}
                                    alt="Lidera Turismo"
                                />
                            </Link>
                        </div>
                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex space-x-4">
                                {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    className={classNames(
                                    item.current ? 'bg-indigo-700 text-white' : 'text-white hover:bg-indigo-500 hover:text-gray-100',
                                    'px-3 py-2 rounded-md text-sm font-medium'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Link>
                                
                                ))}
                                <button
                                    className={classNames(
                                    "Reservas".current ? 'bg-indigo-700 text-white' : 'text-white hover:bg-indigo-500 hover:text-gray-100',
                                    'px-3 py-2 rounded-md text-sm font-medium'
                                    )}
                                    onClick={()=>setOpenConsulta(true)}
                                    aria-current={"Reservas".current ? 'page' : undefined}
                                >
                                    Consultas
                                </button>
                                <Link
                                    key="Reservas"
                                    to='/reservas'
                                    className={classNames(
                                    "Reservas".current ? 'bg-indigo-700 text-white' : 'text-white hover:bg-indigo-500 hover:text-gray-100',
                                    'px-3 py-2 rounded-md text-sm font-medium'
                                    )}
                                    aria-current={"Reservas".current ? 'page' : undefined}
                                >
                                    Reservas
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <a 
                            className="hidden sm:block hover:bg-indigo-500 p-2 rounded-full text-white hover:text-gray-100 focus:outline-none" 
                            href='https://www.facebook.com/lideraturismo'
                            target="_blank"
                            rel='noreferrer'
                        >
                            <FaFacebook className="h-6 w-6" aria-hidden="true" />
                        </a>
                        <a
                            className="hidden sm:block hover:bg-indigo-500 p-2 rounded-full text-white hover:text-gray-100 focus:outline-none"
                            href='https://www.instagram.com/lideraturismo.tuc/'
                            target="_blank"
                            rel='noreferrer'
                        >
                            <FaInstagram className="h-6 w-6" aria-hidden="true" />
                        </a>
                        <a
                            className="hidden sm:block hover:bg-indigo-500 p-2 rounded-full text-white hover:text-gray-100 focus:outline-none"
                            href='https://wa.me/543815532541'
                            target="_blank"
                            rel='noreferrer'
                        >
                            <FaWhatsapp className="h-6 w-6" aria-hidden="true" />
                        </a>
                        {
                            mail ? (
                                <div className='hidden sm:block ml-3'>
                                    <p className='text-white'>{mail}</p>
                                    <button className='bg-orange-500 text-white text-center p-1 rounded-xl w-full' onClick={() => cerrarSesion ()}>Cerrar Sesión</button>
                                </div>
                            ) : (
                                <div className='hidden sm:block ml-3'>
                                    <button className='border text-white p-1 rounded-2xl ml-3 uppercase'
                                        onClick={() => {
                                            setOpenLogin(true)
                                            setVerLogin(true)
                                        }}
                                    >
                                        Iniciar Sesión
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            

            <Disclosure.Panel className="sm:hidden z-50 absolute w-full bg-indigo-600/95">
                <div className="px-2 pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                        <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className={classNames(
                                item.current ? 'bg-indigo-700 text-white' : 'text-white hover:bg-indigo-500 hover:text-gray-100',
                                'block px-3 py-2 rounded-md text-base font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                            >
                            {item.name}
                        </Disclosure.Button>
                    ))}
                    <Disclosure.Button
                        key="Consultas"
                        as="button"
                        onClick={()=>setOpenConsulta(true)}                            
                        className={classNames(
                            "Consultas".current ? 'bg-indigo-700 text-white' : 'text-white hover:bg-indigo-500 hover:text-gray-100',
                            'block px-3 py-2 rounded-md text-base font-medium'
                        )}
                        aria-current={"Consultas".current ? 'page' : undefined}
                        >
                        Consultas
                    </Disclosure.Button>
                    <Disclosure.Button
                        key="Reservas"
                        as="a"
                        href='/reservas'
                        className={classNames(
                        "Reservas".current ? 'bg-indigo-700 text-white' : 'text-white hover:bg-indigo-500 hover:text-gray-100',
                        'block px-3 py-2 rounded-md text-base font-medium'
                        )}
                        aria-current={"Reservas".current ? 'page' : undefined}
                    >
                        Reservas
                    </Disclosure.Button>
                </div>
                <div className='px-3 pt-2 mb-5 flex'>
                    <a 
                        className="hover:bg-indigo-500 p-2 rounded-full text-white hover:text-gray-100 focus:outline-none" 
                        href='https://www.facebook.com/lideraturismo'
                        target={"_blank"}
                        rel='noreferrer'
                    >
                        <FaFacebook className="h-6 w-6" aria-hidden="true" />
                    </a>
                    <a
                        className="hover:bg-indigo-500 p-2 rounded-full text-white hover:text-gray-100 focus:outline-none"
                        href='https://www.instagram.com/lideraturismo.tuc/'
                        target={"_blank"}
                        rel='noreferrer'
                    >
                        <FaInstagram className="h-6 w-6" aria-hidden="true" />
                    </a>
                    <a
                        className="hover:bg-indigo-500 p-2 rounded-full text-white hover:text-gray-100 focus:outline-none"
                        href='https://wa.me/543815532541'
                        target={"_blank"}
                        rel='noreferrer'
                    >
                        <FaWhatsapp className="h-6 w-6" aria-hidden="true" />
                    </a>
                </div>
                {
                    mail ? (
                        <div className='m-5 mb-2'>
                            <p className='text-white text-center mb-2'>{mail}</p>
                            <button className='bg-orange-500 text-white text-center p-1 mb-2 rounded-xl w-full' onClick={() => cerrarSesion ()}>Cerrar Sesión</button>
                        </div>
                        ) : (
                        <div className='m-5 mb-2 border-2 border-white p-1 text-center rounded-2xl'>
                            <Link 
                                className='text-white text-center mb-2 font-bold'
                                to="/login"
                            >
                                Iniciar Sesión
                            </Link>
                        </div>
                            
                        )
                }
            </Disclosure.Panel>
            </>
        )}
        </Disclosure>
    )
}