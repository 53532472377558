import Layout from '../../components/admin/Layout';
import FormUsuario from '../../components/admin/FormUsuario';
import ListadoClientes from '../../components/admin/ListadoClientes';


 const GestionClientes = () => {

    const mail = localStorage.getItem('mail');

    return (
        <Layout
            pagina="Gestión Clientes"
        >
            <div className="w-full h-screen bg-slate-200">
                <div className='w-full p-5'>
                    <FormUsuario />
                </div>
                <ListadoClientes/>
            </div>
        </Layout>
    )
}

export default GestionClientes