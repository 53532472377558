import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Url } from './Url';
import { areaAnimateDU, areaAnimateLR, areaAnimateRL, areaAnimateS } from './Animaciones';


const PaqueteServicios = ({ idpaquete }) => {
    const [Servicios, setServicios] = useState([]);


    useEffect(() => {
        const serv = async () => {
            const url = `${Url}api/paqueteservicios.php?idpaquete=${idpaquete}`;
            const resultado = await axios.get(url);
            //console.log(resultado)
            setServicios(resultado.data.results);
        }
        serv();
    }, [])

    return (
        <>
            {
                Servicios && Servicios.map((servicio, index) => (
                    <div className="w-full" key={index}
                    >
                        <motion.div
                            className='w-full h-72 relative mt-2'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{ once: true, amount: 0.1 }}
                            variants={areaAnimateRL}
                        >
                            <img className="rounded-xl h-72 w-full object-cover" src={Url + servicio.imagen} alt="img" ></img>
                        </motion.div>
                        <motion.div
                            className='w-full pt-3 pb-3'
                            initial={"offscreen"}
                            whileInView={"onscreen"}
                            viewport={{ once: true, amount: 0.1 }}
                            variants={areaAnimateRL}
                        >
                            <h1 className='text-orange-400 text-3xl text-center font-semibold font-roboto'>{servicio.descripcionservicio}</h1>
                            <h3 className='text-orange-400 text-xl font-roboto text-justify'>{servicio.detalles}</h3>
                        </motion.div>
                    </div>

                ))
            }
        </>
    );
}

export default PaqueteServicios