import { Fragment, useRef, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import SubirImagenes from './SubirImagenes'
import axios from 'axios';
import { Url } from '../Url';
import appContext from '../../context/auth/appContext';


export default function ModalAltaDestino(props) {
    const [ open, setOpen ] = useState(false);
    

    const [ nombreDestino, setNombreDestino ] = useState("");
    const [ descripcion, setDescripcion ] = useState("");

    const { idDestino, setIdDestino } = useContext(appContext)

    const cancelButtonRef = useRef(null)

    let tipoImagen = "destinos";
    let idHtml = "imagenesDestino";
    const childRef = useRef()


    const guardarDatos = async () => {
        const formData=new FormData()
        formData.append('nombreDestino',nombreDestino);
        formData.append('descripcion',descripcion);
        try{
            const response = await axios({
                url: Url+'api/guardardestino.php',
                method: 'POST',
                data: formData,
            })
            console.log("id devuelto"+response.data.results.iddestino);

            setIdDestino(response.data.results.iddestino);

        } catch (e) {
            console.log(e)
        }
    }
    useEffect(()=>{
        if (idDestino!==0)
        {
            childRef.current.handleSubmit();
            setNombreDestino("");
            setDescripcion("");
            setIdDestino(0);
        }
    },[idDestino])


    const pull_data = (data) => {
        if (data===0){
        props.func(0);
        setOpen(false)
        }
        //console.log(data); // LOGS DATA FROM CHILD (My name is Dean Winchester... &)
    }

    return (
        <>
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative bg-gray-300 rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-10/12 w-full">
                            <div className="px-4 pt-5 sm:p-6">
                                <div className='flex'>
                                    <label htmlFor="nombreDestino" className="block text-gray-700 font-bold mr-2">Nombre de Destino</label>
                                    <input
                                        type="text"
                                        id="nombreDestino"
                                        className='rounded p-1'
                                        value={nombreDestino}
                                        onChange={(e)=>setNombreDestino(e.target.value)}
                                    />
                                </div>
                                <div className='flex mt-2'>
                                    <label htmlFor="descripcion" className="block text-gray-700 font-bold mr-2">Descripción</label>
                                    <input
                                        type="text"
                                        id="descripcion"
                                        className='rounded p-1'
                                        value={descripcion}
                                        onChange={(e)=>setDescripcion(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="bg-gray-300 px-4 pt-2 pb-5 sm:p-6">
                                <div className="p-1">
                                    <SubirImagenes 
                                        id={idDestino} 
                                        tipo={tipoImagen} 
                                        ref={childRef}
                                        idHtml={idHtml}
                                    />
                                </div>
                            </div>
                            <div className="px-4 pb-5 sm:px-6">
                                <div className="w-full rounded-lg px-5">
                                    <button
                                        type="button"
                                        className={nombreDestino!=="" ? ("w-full shadow-sm p-3 bg-indigo-600 hover:bg-indigo-500 transition-all text-white uppercase font-bold rounded-xl mt-1") : 
                                            ("w-full shadow-sm p-3 transition-all bg-gray-600 hover:bg-gray-500 text-white uppercase font-bold rounded-xl mt-1")}
                                        onClick={nombreDestino!=="" ? (() => guardarDatos()) : 
                                            (() => console.log("vacio"))}
                                    >
                                        Guardar Destino
                                    </button>
                                </div>
                            </div>
                            <div className="px-4 pb-10 sm:px-6">
                                <div className="w-full rounded-lg px-5">
                                    <button
                                        type="button"
                                        className="w-full shadow-sm p-3 bg-gray-600 hover:bg-gray-500 transition-all text-white uppercase font-bold rounded-xl mt-1"
                                        onClick={() => setOpen(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
        </Transition.Root>
        <button className='bg-orange-600 hover:bg-orange-400 p-1 rounded-lg text-white uppercase'
            onClick={() => setOpen(true)}
        >
            Agregar Destino
        </button>
    </>
  )
}