import Layout from '../components/Layout'
import Turismos from '../components/Turismos'
import ListadoPaquetes from '../components/ListadoPaquetes'
import ModalForm from '../components/ModalForm'
import ListadoPromoDestinos from '../components/ListadoPromoDestinos'
import toast, { Toaster } from 'react-hot-toast';

const Internacional = () => {

    return (
        <Layout
            pagina="Turismo Internacional"
        >
            <main className='w-full h-full bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat'>
                <Toaster />
                <div className='bg-indigo-800/90 w-full h-full pb-10'>
                    <div className='w-full h-70 bg-black'>
                        <Turismos
                            idservicio={4}
                        />
                    </div>
                    <div className='md:container mt-5 mx-auto p-5 md:p-0 grid lg:grid-cols-4 md:grid-cols-3 md:gap-10 sm:grid-cols-4 sm:gap-2 gap-2'>
                        <ListadoPaquetes
                            idtipo={4}
                        />
                    </div>

                </div>
            </main>

        </Layout>
    )
}

export default Internacional