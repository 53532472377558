import { useContext } from "react";
import appContext from "../../context/auth/appContext";
import axios from "axios";
import { Url } from "../Url";
import { useFormik } from "formik"
import * as Yup from "yup"


const FEditUsuario = (props) => {
    const { usuarioSelected, setOpenVista, setCliMod, cliMod } = useContext(appContext)

    const formik = useFormik({
        initialValues: {
            nombre: usuarioSelected.nombre,
            apellido: usuarioSelected.apellido,
            telefono: usuarioSelected.telefono,
            mail: usuarioSelected.mail
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .min(3, 'El nombre es muy corto')
                .required('El nombre es obligatorio'),
            apellido: Yup.string()
                .min(3, 'El apellido es muy corto')
                .required('El apellido es obligatorio'),
            telefono: Yup.number()
                .integer('El número no es válido')
                .positive('El número no es válido')
                .typeError('El número no es válido')
                .min(10, 'El número es demasiado corto'),
            mail: Yup.string()
                .email('Email no válido')

        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('id', usuarioSelected.id);
            formData.append('nombre', valores.nombre);
            formData.append('apellido', valores.apellido);
            formData.append('telefono', valores.telefono);
            formData.append('mail', valores.mail);
            try {
                const response = await axios({
                    url: Url + 'api/setcliente.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                let aux=cliMod+1;
                setCliMod(aux)
                setOpenVista(false)
            } catch (e) {
                console.log(e)
            }
        }
    });


    return (
        <>
            <div className="px-4 pt-5 sm:p-6">
                <h2 className="text-3xl text-center text-gray-600 font-bold uppercase">Editar Cliente</h2>
                <form
                    onSubmit={formik.handleSubmit}
                >
                    <>
                        <div className="transition-all px-4 w-full pt-5 sm:p-6">
                            <div className="sm:items-start mt-2">
                                <label htmlFor="apellido" className="block text-gray-600 uppercase font-bold">Apellido</label>
                                <input
                                    type="text"
                                    className="py-3 px-3 w-full rounded"
                                    id="apellido"
                                    placeholder="Ingrese Apellido"
                                    value={formik.values.apellido}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.apellido && formik.errors.apellido ? (
                                    <div className='bg-red-500 text-center uppercase text-white rounded'>
                                        <p>{formik.errors.apellido}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start mt-2">
                                <label htmlFor="nombre" className="block text-gray-700 uppercase font-bold">Nombre</label>
                                <input
                                    type="text"
                                    className="py-3 px-3 w-full rounded"
                                    id="nombre"
                                    placeholder="Ingrese Nombre"
                                    value={formik.values.nombre}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.nombre && formik.errors.nombre ? (
                                    <div className='bg-red-500 text-center uppercase text-white rounded'>
                                        <p>{formik.errors.nombre}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start mt-2">
                                <label htmlFor="telefono" className="block text-gray-700 uppercase font-bold">Nº de Teléfono Celular, Sin 0 y sin 15 (Ej. 3815123456)</label>
                                <input
                                    type="tel"
                                    className="py-3 px-3 w-full rounded"
                                    id="telefono"
                                    placeholder="Nº de tel. Celular"
                                    value={formik.values.telefono}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.telefono && formik.errors.telefono ? (
                                    <div className='bg-red-500 text-center uppercase text-white rounded'>
                                        <p>{formik.errors.telefono}</p>
                                    </div>
                                ) : null}
                            </div>
                            <div className="sm:items-start mt-2">
                                <label htmlFor="mail" className="block text-gray-700 uppercase font-bold">E-mail</label>
                                <input
                                    type="mail"
                                    className="py-3 px-3 w-full rounded"
                                    id="mail"
                                    placeholder="Ingrese su E-mail"
                                    value={formik.values.mail}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.mail && formik.errors.mail ? (
                                    <div className='bg-red-500 text-center uppercase text-white rounded'>
                                        <p>{formik.errors.mail}</p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="px-4 pb-10 sm:px-6">
                            <div className="w-full rounded-lg">
                                <input
                                    type="submit"
                                    className="w-full shadow-md p-3 bg-indigo-600 text-gray-100 hover:text-gray-300 hover:cursor-pointer transition-all uppercase font-bold rounded-lg mt-1"
                                    value="Guardar"
                                />
                            </div>
                        </div>
                    </>
                </form>
            </div>
            <div className="px-4 pb-10 sm:px-6">
                <div className="w-full rounded-lg px-5">
                    <button
                        type="button"
                        className="w-full shadow-sm p-3 bg-gray-600 hover:bg-gray-500 transition-all text-white uppercase font-bold rounded-xl mt-1"
                        onClick={() => setOpenVista(false)}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </>

    )
}

export default FEditUsuario