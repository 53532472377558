import React from 'react'
import Layout from '../../components/admin/Layout'
import ListadoReservasNuevas from '../../components/admin/ListadoReservasNuevas'

const GestionReservas = () => {
    const tipo = localStorage.getItem('ld_tip');


    return (
        <Layout
            pagina="Gestión de Reservas"
        >
            {tipo === "1" && (
                <div
                    className='w-full h-screen bg-slate-200'
                >
                    <h1 className='mt-10 text-gray-500 text-center mb-5 text-3xl'>Gestión de Reservas</h1>
                    <ListadoReservasNuevas />
                </div>
            )}
        </Layout>
    )
}

export default GestionReservas