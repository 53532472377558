import { Fragment, useRef, useState, useContext, useEffect } from 'react'
import authContext from '../../context/auth/authContext'
import Alerta from '../../components/Alerta'
import { useFormik } from "formik"
import * as Yup from "yup"
import { Link } from 'react-router-dom'

const LoginAdmin = () => {
    const AuthContext = useContext(authContext);

    const { autenticado,mensaje, iniciarSesionAdmin } = AuthContext;

    const formik = useFormik({
        initialValues:{
            usuario: '',
            password: ''
        },
        validationSchema: Yup.object({
            usuario: Yup.string()
                    .required("El nombre de usuario es obligatorio"),
            password: Yup.string()
                    .required("Debe ingresar un Password")
                    .min(6,"Debe contar con un mínimo de 6 caracteres")
        }),
        onSubmit: valores => {
            iniciarSesionAdmin(valores)
        }
    });
    const areaAnimate={
        offscreen:{x:0, opacity:0},
        onscreen:{
            x:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    useEffect(() => {
        if (autenticado) {
            
        }
    }, [autenticado])
    

    return (
        <div>
            { mensaje ? <Alerta /> : null }
            <form
                className='w-full md:w-1/4 mx-auto mt-10 p-5 bg-indigo-600 rounded-2xl'
                onSubmit={formik.handleSubmit}
            >
                <div className="transition-all w-full pt-5 mt-5">
                    <div className="sm:items-start">
                        <label htmlFor="usuario" className="block mt-5 text-white uppercase font-bold">Nombre de Usuario</label>
                        <input 
                            className="px-3 py-3 w-full rounded" 
                            id="usuario" 
                            placeholder="Ingrese su Nombre de Usuario" 
                            type="text" 
                            value={formik.values.usuario}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus
                        />
                        { formik.touched.usuario && formik.errors.usuario ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.usuario}</p>
                            </div>
                        ) : null }
                    </div>
                    <div className="sm:items-start mt-5">
                        <label htmlFor="password" className="block text-white uppercase font-bold">Contraseña</label>
                        <input 
                            className="px-3 py-3 w-full rounded" 
                            id="password" 
                            placeholder="Ingrese su Contraseña" 
                            type="password" 
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        { formik.touched.password && formik.errors.password ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.password}</p>
                            </div>
                        ) : null }
                    </div>
                </div>
                <div className="bg-indigo-600 pt-10 pb-2">
                    <div className="bg-indigo-600 w-full rounded-lg">
                        <input
                            type="submit"
                            className="w-full shadow-md p-3 bg-orange-600 text-gray-100 hover:text-gray-300 transition-all uppercase font-bold rounded-lg mt-5"
                            value="Iniciar Sesión"
                        />
                    </div>
                </div>
            </form>
        </div>
  )
}

export default LoginAdmin